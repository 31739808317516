import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContentfulService } from 'src/app/services/contentful.service';
import { PharmacyService } from '../pharmacy.service';
import { Location } from '@angular/common';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {

  @ViewChild('closebutton', {
    static: false
  }) closebutton;

  @ViewChild('pdfContent', {
    static: false
  }) pdfContent: ElementRef;

  myForm!: FormGroup
  submitted = false;
  selectProducts: any;
  drugname: any;
  myemailForm: FormGroup;
  emailContent: any;
  imageurl: string;
  // data: any;
  // currentIndex = 0;

  constructor(private fb: FormBuilder, private route: Router, private contentfulservice: ContentfulService, private dataService: PharmacyService, private _location: Location, private toast: ToastrService,) { }

  ngOnInit() {

    this.dataService.allPassedData.subscribe((allPassedData) => {
      this.selectProducts = allPassedData;
      let a = (this.selectProducts); // print the data
      //console.log(a);
    })
    this.dataService.drugName.subscribe((drugName) => {
      this.drugname = drugName;
      let b = (this.drugname); // print the data
      //console.log(b);
    })



    this.myForm = this.fb.group({
      phonenumber: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required]]
    })

    this.myemailForm = this.fb.group({
      fromEmail: ['support@ogesupport.com'],
      toEmail: ['', [Validators.required]],
      subject: ['A discount card has been shared with you'],
      body: ['']
    })
  }

  get f() { return this.myForm.controls; }
  onSubmit() {
    if (!this.myForm.invalid) {
      // console.log(this.myForm.value);
    }
  }

  return() {
    //this.route.navigateByUrl('pharmacy')
    this._location.back();
  }

  // groupArr
  // getData(){
  //   this.contentfulservice.getMedData().subscribe((res) =>{
  //     this.data = res.drugs
  //     this.groupArr = this.data.reduce((r,{chainCode})=>{
  //       if(!r.some(o=>o.chainCode==chainCode)){
  //         r.push({chainCode,groupItem:this.data.filter(v=>v.chainCode==chainCode)});
  //         this.increment()
  //   }
  //   return r;
  //   },[]);
  //     console.log(this.groupArr)
  //   })
  // }

  // increment() {
  //   this.currentIndex++;
  // }


  print() {
    //alert('Iam Print')
    window.print()
    //const pdfContent = this.pdfContent.nativeElement;

    /*html2canvas(pdfContent).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
    
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
      // Open the print dialog
      html2canvas(pdfContent).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4'
        });
      
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
        // Open the print dialog
        const pdfData = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfData);
        const newTab = window.open(pdfUrl, '_blank');
      
        newTab.onload = function() {
          newTab.print();
          newTab.close();
          URL.revokeObjectURL(pdfUrl);
        };
      });
    });*/
    

  }

  takeScreenshot(): void {

    const element = document.querySelector<HTMLElement>('#elementToCapture');
    console.log(element)
    // Replace with the ID of the element you want to capture or remove to capture entire page
    html2canvas(element).then(canvas => {
      this.imageurl = canvas.toDataURL();
      const image = document.getElementById('showimg') as HTMLImageElement;
      if (image) {
        image.src = this.imageurl;
      } else {
        console.error("Element with ID 'showimg' not found");
      }
    });

    this.openModal();

  }

  openModal() {
    document.getElementById("openbutton").click();
  }

  closeModal() {
    this.closebutton.nativeElement.click();
  }

  onemailSubmit() {
    this.emailContent = document.getElementById('elementToCapture').innerHTML;
    this.myemailForm.value.body = encodeURI(this.emailContent);
    this.contentfulservice.getMailInfo(this.myemailForm.value).subscribe(res => {
      console.log(res)
      this.closeModal();
      this.toast.success('Mail Sent')
    });

  }
}