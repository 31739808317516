import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Player from '@vimeo/player';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
@Component({
  selector: 'app-qsa-video',
  templateUrl: './qsa-video.component.html',
  styleUrls: ['./qsa-video.component.scss']
})

@Pipe({ name: 'safe' })

export class QsaVideoComponent implements OnInit {
  seotitle: any;
  seodes: any;
  hidePagesFromSearchEnginesNoindex: any;
  excluedLinksFromSearchRankingsNofollow: any;
  pagekeywords: any;

  constructor(private titleService: Title,private contentfulservice : ContentfulService, private metaservice: Meta) { }
  
  @ViewChild('playerContainer' , {static:false}) playerContainer: ElementRef;
  
  videoUrl: any

  ngOnInit() {
    this.getQsaContent();
    this.getVimeo();

    this.contentfulservice.getdata('3QmTZhkKxdkHBK7b4nrZuS').subscribe(res =>{
      console.log(res.fields.seoTitle)
      this.titleService.setTitle(res.fields.seoTitle)
      this.seotitle = res.fields.seoTitle;
      this.seodes = res.fields.seoDescription;
      this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
      this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
      this.pagekeywords = res.fields.keywords;
      //console.log(this.seotitle, this.seodes, this.hidePagesFromSearchEnginesNoindex, this.excluedLinksFromSearchRankingsNofollow, this.pagekeywords )

      this.metaservice.addTag( { name:'description',content: this.seodes});
      this.metaservice.addTag( { name:'application-name',content: this.seotitle});
      this.metaservice.addTag( { name:'keywords',content: this.pagekeywords});
      this.metaservice.addTag( { name:'noindex',content: this.hidePagesFromSearchEnginesNoindex});
      this.metaservice.addTag( { name:'nofollow',content:  this.excluedLinksFromSearchRankingsNofollow});
    })
  }
  qsaContent:any;
  qsaHeading:any;
  getQsaContent(){
    this.contentfulservice.getdata('4GBu1aoPWpCQCUS4h1vypO').subscribe(res =>{
      this.qsaHeading = res.fields.heading;
      this.qsaContent  = res.fields.copyBlock;
    });
  }

  public options:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options);
    }
}

  // videoURL = "https://www.youtube.com/embed/LFoz8ZJWmPs";

  // transform(url) {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //    }

  getVimeo(){
    this.contentfulservice.getdata('54oKujDy6Bwh97UCYNuyP3').subscribe(res=>{
       this.videoUrl = res.fields.videoUrl;
       const player = new Player(this.playerContainer.nativeElement, { url: this.videoUrl });
       player.play().then(() => {
       });
    })
    
  }

  

}
