import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from, throwError, timer, zip } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { environment } from 'src/environments/environment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { retryWhen, mergeMap, concatMap } from 'rxjs/operators';
import Player from '@vimeo/player';


@Component({
    selector: 'app-landing-page-preview',
    templateUrl: './landing-page-preview.component.html',
    styleUrls: ['./landing-page-preview.component.scss']
})
export class LandingPagePreviewComponent implements OnInit {

    panelOpenState = false;
    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    step: number = 1;
    logo: string;
    mainHead: Observable<[]>;
    mainContent: Observable<[]>;
    mainSub: Observable<[]>;
    unordered: Observable<[]>;
    listed1: Observable<[]>;
    listed2: Observable<[]>;
    listed3: Observable<[]>;
    landFootImg: String;
    copyRights: Observable<[]>;
    siteKey = environment.siteKey
    sampleImg: string;
    headerImg:string;
    prescribe: any;
    dynamicMsg: any;
    landingForm: FormGroup;
    captchaForm!: FormGroup;
    submitted = false;
    isSubmitted = false;
    reqId: any
    indication: any;
    isi: any;
    sponseredhero_id: any;
    resid: string;
    landingpageid: string;
    heading_id: any;
    drugdata: any;
    versionone_med: any;
    versionone_medname: any;
    urllink: any;
    result2: any;
    seotitle: any;
    seodes: any;
    hidePagesFromSearchEnginesNoindex: any;
    excluedLinksFromSearchRankingsNofollow: any;
    pagekeywords: any;
    ogeUrl: any;
    drug: any;
    seoid: any;
    content_id: any;
    content_id2: any;
    content_id3: any;
    content_id4:any;
    isi_id: any;
    presid: any;
    branddiseaseResources_id: any;
    copyRights_id: any;
    sessionid: any;
    sid: any;
    footerimage_id: any;
    sponserdherores: any;
    seodatares: any;
    resourcedatares: any;
    shortstickyisi: any;
    resourceprimarycolor: any;
    resourcetertiaryColor: any;
    resourcesecondarycolor: any;
    gradient1: any;
    gradient2: any;
    gradient3: any;
    footgradient1: any;
    footgradient2: any;
    gradientrotation2: any;
    footgradientrotation2: any;
    resourcefontColor: any;
    resourcefontLinkColor: any;
    resourcefontLinkRolloverColor: any;
    brandresourceres: any;
    presinfo_id: any;
    presinternalname: any;
    p_file: any;
    resourceh1color: any;
    resourcehorizontalRule: any;
    resourcebuttonBackgroundRollOverColor: any;
    resourceh2color: any;
    resourceh3color: any;
    resourcebuttonBackgroundColor: any;
    resourcebuttonFontColor: any;
    resourcebuttonRolloverFontColor: any;
    resourcebuttonBackgroundActiveColor: any;
    resourcebuttonActiveFontColor: any;
    resourcefontLink: any;
    resourcefontFamily: any;

    readonly SITE_KEY = environment.siteKey;
    action = "register";

    token: string = undefined;
    resourceform_url: any;
    copyblocklist: any;
    resourcebrandFooterBackgroundColor: any;
    headerIndicationHeaderFontSize: any;
    headerIndicationCopyFontSize: any;
    headerIndicationFontColor: any;
    isiHeadersFontSize: any;
    isiHeadersColors: any;
    segments: any;
    drungnameinurl: string;
    landingpageemailbody: string;
    landingpageemailsubject: any;
    references: any;
    copyBlockFootnotes: any;
    alertmssg: boolean;
    allcontents: boolean;
    CTA_id: any;
    CTAButtonidarray: any;
    CTAbuttonarray: any[];
    videoUrl: any;
    headerimage:any;

    @ViewChild('playerContainer' , {static:false}) playerContainer: ElementRef;
    videoTitle: any;
    vimeo_video: boolean;
    thankYouBox: any;
    thankYouBoxtest: any;


    constructor(private _formBuilder: FormBuilder, private contentfulservice: ContentfulService,
        private router: Router, private titleService: Title, private http: HttpClient,
        private toast: ToastrService, private metaservice: Meta, private reCaptchaV3Service: ReCaptchaV3Service, private actroute: ActivatedRoute) {


        this.actroute.url.subscribe(segments => {
            this.segments = segments.map(segment => segment.path).slice(1, 4); // extract segments 1-3
            // console.log(this.segments.length)
        });

        this.getalllandingpagecontents();


    }


    ngOnInit() {

        this.alertmssg = false;
        this.allcontents = true;


        //alert("this is landing page")
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });

        //this.contentfulservice.getDetails().subscribe((res) => {
        //  console.log(res)
        //    this.reqId = res._id
        //})


        this.contentfulservice.getdatapreview('1vt9awP0oEXEMyyBAixJzP').subscribe(res => {
            //console.log(res.fields.seoTitle)
            this.titleService.setTitle(res.fields.seoTitle)
        })

        this.landingForm = new FormGroup({
            toEmail: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            npi: new FormControl(''),
            ogeUrl: new FormControl(''),
            drug: new FormControl('drugName'),
            fromEmail: new FormControl('support@ogesupport.com'),
            body: new FormControl(''),
            subject: new FormControl('Outcome Guided Engagement (OGE) resource you ordered is here!')
        });

        this.captchaForm = new FormGroup({
            //captcha: new FormControl('', [Validators.required]),
            acceptTerms: new FormControl('', [Validators.required]),
        })



        /*try{
          console.log(this.safetyInformation.content[4].content[0].value, this.prescribe.content[8].content[1].content[0].value )
        }catch(err){
          console.log(err)
          document.getElementById("intermediateline").style.display="none";
        }*/


    }

    getalllandingpagecontents() {
        // this.contentfulservice.getVersiononeMedicines()
        //     .pipe(this.retryWithDelay())
        //     .subscribe(
        //         (res) => {
        //             console.log(res);
        //             this.drugdata = res;
        //             this.versionone_med = res.results.map(item => {
        //                 //console.log(item);
        //                 return item.contentfulLandingPageId;
        //             });

        //             let str = window.location.href;
        //             this.ogeUrl = str;
        //             if (this.segments.length == 1) {
        //                 this.drungnameinurl = (str.substring(str.lastIndexOf('/') + 1));
        //                 //console.log(this.drungnameinurl)
        //             }
        //             if (this.segments.length == 2) {
        //                 let Url = str;
        //                 let wordinurl = (Url.substring(0, Url.lastIndexOf('/')));
        //                 this.drungnameinurl = (wordinurl.substring(wordinurl.lastIndexOf('/') + 1));
        //                 //console.log(this.drungnameinurl)
        //             }
        //             this.versionone_medname = (filterItems(this.versionone_med, this.drungnameinurl))
        //             this.result2 = this.drugdata.results.find(item => item.contentfulLandingPageId === this.versionone_medname[0]);
        //             if (this.result2 !== undefined) {

        //                 this.drug = this.result2.name;
        //                 this.resid = this.result2.contentfulResourceId;
        //                 document.getElementById('top').style.display = "block"
        //                 document.getElementById('alertmssg').style.display = "none"
        //             }
        //             else {
        //                 //alert("No Matching Drug Found")
        //                 document.getElementById('top').style.display = "none"
        //                 document.getElementById('alertmssg').style.display = "block"
        //             }
        //             //console.log(this.result2);
        //             //this.resid = this.result2.contentfulId;

        //             //console.log(this.resid);
        //             this.contentfulservice.resid_Visibility(this.resid)
        //             //this.contentfulservice.setTest(this.resid);
        //             //alert("GV: " + this.contentfulservice.getTest());
        //             //this.landingpageid = this.result2.landingPageId;
        //             this.landingpageid = this.result2.contentfulLandingPageId;
        //             console.log(this.resid, this.landingpageid)

        //             this.getlandingpagedata();
        //             //this.getlandingpagedatathroughresid();

        //             this.getHizentraimage();
        //             //this.getCslimage();
        //             this.getFooterimg();




        //             this.getColor();
        //         },
        //         (error) => {
        //             console.error(error);
        //         }
        //     );
        // function filterItems(arr, query) {
        //     // return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
        //     return arr.filter(element => element.toLowerCase() === query.toLowerCase());
        // }

        let str = window.location.href;
        this.ogeUrl = str;
        let wordinurl = (this.ogeUrl.substring(this.ogeUrl.lastIndexOf('/') + 1));
        console.log(wordinurl)
        // if (wordinurl !== undefined) {
        //   document.getElementById('top').style.display = "block";
        //   document.getElementById('alertmssg').style.display = "none";
        // } else {
        //   document.getElementById('top').style.display = "none";
        //   document.getElementById('alertmssg').style.display = "block";
        // }


        this.landingpageid = wordinurl
        console.log(this.landingpageid)

        this.contentfulservice.getAlldatapreview().subscribe(res => {
            let masterContent = res.items;
            // console.log(masterContent)
            let id = res.items.map(item => {
                //console.log(item);
                return item.sys.id;
            });
            //console.log(id)
            let filteredid = (filterItems(id, this.landingpageid))
            console.log(filteredid.length)
            if (filteredid.length == 0) {
                this.alertmssg = true;
                this.allcontents = false;
            }
        });

        function filterItems(arr, query) {
            // return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
            return arr.filter(element => element.toLowerCase() === query.toLowerCase());
        }

        this.getlandingpagedata();
        //this.getlandingpagedatathroughresid();
        this.getHizentraimage();
        //this.getCslimage();
        this.getFooterimg();
        this.getColor();
        // this.getVimeo();
    }

    // retryWithDelay(maxRetry: number = 3, delay: number = 2000): (src: Observable<any>) => Observable<any> {
    //     console.log("retried")
    //     return (src: Observable<any>) =>
    //         src.pipe(
    //             retryWhen((errors: Observable<any>) =>
    //                 errors.pipe(
    //                     mergeMap((error, i) =>
    //                         i < maxRetry ? timer(delay) : throwError(error)
    //                     )
    //                 )
    //             )
    //         );
    // }

    scrollToElement(element: HTMLElement) {
        element.scrollIntoView({
            behavior: "smooth"
        });
    }

    private __getElementById(id: string): HTMLElement {
        //console.log("element id : ", id);
        const element = document.getElementById(id);
        return element;
    }

    scrollToElementById(id: string) {
        const element = this.__getElementById(id);
        this.scrollToElement(element);
    }

    next() {
        this.submitted = true
        if (!this.captchaForm.invalid) {
            console.warn("Token");

            this.reCaptchaV3Service.execute(
                this.SITE_KEY,
                this.action,
                token => {
                    // console.log(`%c This is your token :${token}`, "color: blue;", token);
                    this.token = token;
                },
                {
                    useGlobalDomain: false // optional
                }
            );
            this.step = this.step + 1;
        }
    }

    get f() {
        return this.captchaForm.controls;
    }
    get for() {
        return this.landingForm.controls;
    }

    getresourceformURL() {
        if (this.sponserdherores.fields.hasOwnProperty("ogeqsaColumnRight")) {
            this.content_id = this.sponserdherores.fields.ogeqsaColumnRight.sys.id;
        } else { console.log("ogeqsaColumnRight not exists") }
        this.contentfulservice.getdatapreview(this.content_id).subscribe(res => {

            let resourceform_id = res.fields.resourceForm.sys.id;
            //console.log(resourceform_id)
            this.contentfulservice.getdatapreview(resourceform_id).subscribe(res => {
                this.resourceform_url = res.fields.resourceUrlToRedirectToOnSubmit;
                //console.log(this.resourceform_url)
                if (res.fields.hasOwnProperty('emailContentSentOnSubmit')) {
                    let body = res.fields.emailContentSentOnSubmit;
                    this.landingpageemailbody = this._returnHtmlFromRichText(body)
                    //console.log(this.landingpageemailbody)
                } else {
                    console.log("emailContentSentOnSubmit not exits")
                }
                if (res.fields.hasOwnProperty('emailSubjectSentOnSubmit')) {
                    let body = res.fields.emailSubjectSentOnSubmit;
                    this.landingpageemailsubject = body
                    //console.log(this.landingpageemailbody)
                } else {
                    console.log("emailSubjectSentOnSubmit not exits")
                }
            })
        })
    }

    getCTAactionbutton() {
        //alert("executed")
        this.CTA_id = this.sponserdherores.fields.branddiseaseHeader.sys.id;
        //alert(this.CTA_id)
        this.contentfulservice.getdatapreview(this.CTA_id).subscribe(res => {
            console.log(res)
            this.CTAButtonidarray = res.fields.ctaActions
            let CTAbuttonids = this.CTAButtonidarray.map(id => id.sys.id)
            console.log(CTAbuttonids)

            let responseCTADataArray = [];
            const requests$ = [];

            let i;

            for (let i = 0; i < CTAbuttonids.length; i++) {
                const value = CTAbuttonids[i];
                const urlWithQuery = `${value}`;
                //console.log(urlWithQuery)
                if (urlWithQuery !== undefined) {
                    requests$.push(from(this.contentfulservice.getdata(urlWithQuery)));
                }
            }

            zip(...requests$).pipe(
                concatMap(responses => {
                    responseCTADataArray = [...responseCTADataArray, ...responses];
                    if (responseCTADataArray.length === CTAbuttonids.length) {
                        console.log(responseCTADataArray)
                        this.CTAbuttonarray = responseCTADataArray;
                    }
                    return [];
                })
            ).subscribe();
        })
    }

    getVimeo(){
        if(this.sponserdherores.fields.hasOwnProperty('embeddedVideo')){
            
        this.vimeo_video = true;
        let embededvideo = this.sponserdherores.fields.embeddedVideo.sys.id
        this.contentfulservice.getdata(embededvideo).subscribe(res=>{
           this.videoUrl = res.fields.videoUrl;
           this.videoTitle = res.fields.videoTitle;
           const player = new Player(this.playerContainer.nativeElement, { url: this.videoUrl });
           player.play().then(() => {
           });
        })
    }else{
        console.log("video not exists")
        this.vimeo_video = false;
    }
        
      }

    userData() {
        this.isSubmitted = true;

        if (!this.landingForm.invalid) {
            let url = 'https://api.ogesupport.com/v1/resources/1vt9awP0oEXEMyyBAixJzP/request';
            this.http.post(url, this.landingForm.value).subscribe(res => {
                this.sessionid = res;
                this.sid = this.sessionid._id;

                this.toast.success('Submitted');

                let url = this.resourceform_url + `${this.sid}`
                const updatedHtmlContent = this.landingpageemailbody.replace(/\[URL Link\]/g, `<a href="${url}" target="_blank">${url}</a>`);
                this.landingpageemailbody = updatedHtmlContent;
                this.landingForm.get('body').setValue(encodeURI(this.landingpageemailbody));
                this.landingForm.get('subject').setValue(this.landingpageemailsubject)
                //console.log(encodeURI(this.landingpageemailbody))
                //this.landingForm.value.body = encodeURI(this.landingpageemailbody);
                ///this.landingForm.get('body').setValue(this.resourceform_url + `${this.sid}`);
                this.landingForm.get('ogeUrl').setValue(this.resourceform_url + `${this.sid}`);
                console.log(this.landingForm.value)
                let mailurl = 'https://api.ogesupport.com/v1/resources/1vt9awP0oEXEMyyBAixJzP/access';
                this.http.post(mailurl, this.landingForm.value).subscribe(res => {
                    console.log(res);
                    //window.location.href = this.resourceform_url + `${this.sid}`;
                    window.open(this.resourceform_url + `${this.sid}`, '_blank');

                    this.isSubmitted = false;
                    this.submitted = false;
                    this.landingForm.reset();
                    this.step = this.step - 1;
                    this.captchaForm.reset(); // Reset form after second request is executed
                });
            });
        }
    }



    getlandingpagedata() {
        this.contentfulservice.getdatapreview(this.landingpageid).subscribe(res => {
            //alert("executed landingpagedata")
            this.seodatares = res;
            this.getSeoData();
            this.getsponseredhero();
            this.getInformation();
        })
    }
    /*getlandingpagedatathroughresid() {
        this.contentfulservice.getdatapreview(this.resid).subscribe(res => {
            this.resourcedatares = res
            this.getInformation();
            //this.getPrescribe();
            this.branddiseaseResources_id = this.resourcedatares.fields.branddiseaseResources.sys.id;
            this.contentfulservice.getdatapreview(this.branddiseaseResources_id).subscribe(res => {
                this.brandresourceres = res
                //this.copyRights_id = res.fields.footer.sys.id;
                //this.getCopyright();

                this.getprescribingInformation();
            })
        })
    }*/

    getsponseredhero() {
        this.sponseredhero_id = this.seodatares.fields.sponsoredHero.sys.id;
        this.contentfulservice.getdatapreview(this.sponseredhero_id).subscribe(res => {
            // alert("executed sponsered hero")
            this.sponserdherores = res;
            // console.log(this.sponserdherores)
            if (res.fields.hasOwnProperty("footer")) {
                this.copyRights_id = res.fields.footer.sys.id;
            } else { console.log("footer not exists") }
            // if(res.fields.hasOwnProperty("thankYouBox")){
            //     this.thankYouBoxtest = res.fields.thankYouBox;
            //     console.log("thankyoubox", this.thankYouBox)
            // }
            this.getCopyright();
            console.log(this.sponserdherores)
            this.getMainhead();
            this.getPrivacyContent();
            this.getListedtext();
            this.getSampleimage();
            this.getheaderimage();
            this.getDynamicmsg();
            this.getresourceformURL();
            this.getCTAactionbutton();
            this.getprescribingInformation();
            this.getreferences();
            this.getVimeo();
            this.getthankYouBox();
        })
    }

    getSeoData() {
        this.seoid = this.seodatares.fields.seoMetadata.sys.id;
        this.contentfulservice.getdatapreview(this.seoid).subscribe(res => {
            this.seotitle = res.fields.seoTitle;
            this.seodes = res.fields.seoDescription;
            this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
            this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
            this.pagekeywords = res.fields.keywords;
            //console.log(this.seotitle, this.seodes, this.hidePagesFromSearchEnginesNoindex, this.excluedLinksFromSearchRankingsNofollow, this.pagekeywords )

            this.metaservice.addTag({ name: 'description', content: this.seodes });
            this.metaservice.addTag({ name: 'application-name', content: this.seotitle });
            this.metaservice.addTag({ name: 'keywords', content: this.pagekeywords });
            this.metaservice.addTag({ name: 'noindex', content: this.hidePagesFromSearchEnginesNoindex });
            this.metaservice.addTag({ name: 'nofollow', content: this.excluedLinksFromSearchRankingsNofollow });
        })
    }



    getHizentraimage() {
        this.contentfulservice.getdatapreview(this.landingpageid).pipe(
            mergeMap(res => {
                let bid = res.fields.sponsoredHero.sys.id;
                return this.contentfulservice.getdatapreview(bid);
            }),
            mergeMap(res => {
                let bid1 = res.fields.branddiseaseHeader.sys.id;
                return this.contentfulservice.getdatapreview(bid1);
            }),
            mergeMap(res => {
                let bid2 = res.fields.logoimage.sys.id;
                return this.contentfulservice.getdatapreview(bid2);
            }),
            mergeMap(res => {
                let bid3 = res.fields.imageWrapper.sys.id;
                return this.contentfulservice.getdatapreview(bid3);
            }),
            mergeMap(res => {
                let bid4 = res.fields.image.sys.id;
                return this.contentfulservice.getAssetspreview('/' + bid4 + '/');
            }),
            retryWhen(errors => {
                return errors.pipe(
                    mergeMap(error => {
                        if (error.status === 429) {
                            console.log("got 429, so retried")
                            const retryAfterSeconds = Math.floor(Math.random() * 10) + 1; // Wait 1-10 seconds
                            return timer(retryAfterSeconds * 1000);
                        }
                        return throwError(error);
                    })
                );
            })
        ).subscribe(imgData => {
            this.logo = imgData.fields.file.url;
        });
    }

    getFooterimg() {
        this.contentfulservice.getdatapreview(this.landingpageid).pipe(
            mergeMap(res => {
                let bid = res.fields.sponsoredHero.sys.id;
                return this.contentfulservice.getdatapreview(bid);
            }),
            mergeMap(res => {
                let bid1 = res.fields.footer.sys.id;
                return this.contentfulservice.getdatapreview(bid1);
            }),
            mergeMap(res => {
                let bid2 = res.fields.logoimage[0].sys.id;
                return this.contentfulservice.getdatapreview(bid2);
            }),
            mergeMap(res => {
                let bid3 = res.fields.imageWrapper.sys.id;
                return this.contentfulservice.getdatapreview(bid3);
            }),
            mergeMap(res => {
                let bid4 = res.fields.image.sys.id;
                return this.contentfulservice.getAssetspreview('/' + bid4 + '/');
            }),
            retryWhen(errors => {
                return errors.pipe(
                    mergeMap(error => {
                        if (error.status === 429) {
                            console.log("got 429, so retried")
                            const retryAfterSeconds = Math.floor(Math.random() * 10) + 1; // Wait 1-10 seconds
                            return timer(retryAfterSeconds * 1000);
                        }
                        return throwError(error);
                    })
                );
            })
        ).subscribe(imgData => {
            this.landFootImg = imgData.fields.file.url;
        });
    }

    getMainhead() {
        if (this.sponserdherores.fields.hasOwnProperty("mainTextBlock")) {
            this.heading_id = this.sponserdherores.fields.mainTextBlock.sys.id;
        } else { console.log("MainTextBlock not exists") }
        this.contentfulservice.getdatapreview(this.heading_id).subscribe(res => {
            this.mainHead = res.fields.heading;
            this.mainContent = res.fields.copyBlock;
            //console.log(this.mainHead, this.mainContent);
        })
    }
    privacyContent: any;
    getPrivacyContent() {
        if (this.sponserdherores.fields.hasOwnProperty("ogeqsaColumnRight")) {
            this.content_id = this.sponserdherores.fields.ogeqsaColumnRight.sys.id;
        } else { 
            // console.log("ogeqsaColumnRight not exists") 
        }
       
        this.contentfulservice.getdatapreview(this.content_id).subscribe(res => {

            let resourceform_id = res.fields.resourceForm.sys.id;
            //console.log(resourceform_id)
            this.contentfulservice.getdatapreview(resourceform_id).subscribe(res => {
                let resourceset_id = res.fields.resourceSet[0].sys.id;
                //console.log(resourceset_id)
                this.contentfulservice.getdatapreview(resourceset_id).subscribe(res => {
                    let privacy_id = res.fields.formResources[0].sys.id;
                    this.contentfulservice.getdatapreview(privacy_id).subscribe(res => {
                        this.privacyContent = res.fields.label;
                    })
                })
            })
        })
    }



    getListedtext() {
        
        if (this.sponserdherores.fields.hasOwnProperty("ogeqsaColumnRight")) {
            this.content_id2 = this.sponserdherores.fields.ogeqsaColumnRight.sys.id;
        } else { 
            // console.log("ogeqsaColumnRight not exists") 
        }
        //alert(this.content_id2)
        this.contentfulservice.getdatapreview(this.content_id2).subscribe(res => {
            let maintext_id = res.fields.mainTextBlock.sys.id;
            this.contentfulservice.getdatapreview(maintext_id).subscribe(res => {
                this.copyblocklist = res.fields.copyBlock;
                if (res.fields.heading !== undefined) {
                    this.mainSub = res.fields.heading;
                }
                if (res.fields.copyBlock.content[0].content[0].value !== undefined) {
                    this.unordered = res.fields.copyBlock.content[0].content[0].value;
                }
                if (res.fields.copyBlock.content[1].content[0].content[0].content[0].value !== undefined) {
                    this.listed1 = res.fields.copyBlock.content[1].content[0].content[0].content[0].value;
                }
                if (res.fields.copyBlock.content[1].content[1].content[0].content[0].value !== undefined) {
                    this.listed2 = res.fields.copyBlock.content[1].content[1].content[0].content[0].value;
                }
                if (res.fields.copyBlock.content[1].content[2].content[0].content[0].value !== undefined) {
                    this.listed3 = res.fields.copyBlock.content[1].content[2].content[0].content[0].value;
                }
                //console.log(this.listed3)
            })
        })
    }

    public safetyInformation: any;
    getInformation() {
        let a = this.seodatares.fields.sponsoredHero.sys.id;
        this.contentfulservice.getdatapreview(a).subscribe(res => {
            if(res.fields.hasOwnProperty("stickyIsi")){
            let b = res.fields.stickyIsi.sys.id
            this.contentfulservice.getdatapreview(b).subscribe(res => {
                if (res.fields.hasOwnProperty("indications")) {
                    this.indication = res.fields.indications;
                } else { console.log("indications not exists") }
                if (res.fields.hasOwnProperty("safetyInformation")) {
                    this.isi = res.fields.safetyInformation
                } else { console.log("safetyInformation not exists") }
            });
        }
        });
    }

    public options: any = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
        }
    }

    _returnHtmlFromRichText(richText) {
        if (richText === undefined || richText === null || richText.nodeType !== 'document') {
            return '<p></p>';
        }
        return documentToHtmlString(richText, this.options);
    }

    //getCslimage() {
    //    this.contentfulservice.getAssetspreview('/2aKt26etDN7EJwb2kqn1MI/').subscribe(res => {
    //this.landFootImg = res.fields.file.url
    //    });
    //}



    getSampleimage() {

        if (this.sponserdherores.fields.hasOwnProperty("ogeqsaColumnLeft")) {
            this.content_id3 = this.sponserdherores.fields.ogeqsaColumnLeft.sys.id;
        } else { console.log("ogeqsaColumnLeft not exists") }
        
        // alert(this.content_id3)
        this.contentfulservice.getdatapreview(this.content_id3).subscribe(res => {
            let imgwrap_id = res.fields.imageWrapper.sys.id;
            this.contentfulservice.getdatapreview(imgwrap_id).subscribe(res => {
                let imgid = res.fields.image.sys.id;
                //alert(imgid)
                this.contentfulservice.getAssetspreview('/' + imgid + '/').subscribe(imgData =>
                    this.sampleImg = imgData.fields.file.url
                );
            })
        })
    }
    getheaderimage(){ 
        // alert("executed")
        if(this.sponserdherores.fields.hasOwnProperty('headerImage')){ 
            let headerimage = this.sponserdherores.fields.headerImage.sys.id;
            // alert(headerimage)
            this.contentfulservice.getAssetspreview('/' + headerimage + '/').subscribe(res=>{
                // alert("exists")
               this.headerImg = res.fields.file.url;
            //    console.log(this.headerImg)   
            })
        }else{
            console.log(" headerImg not exists") 
        } 
    }

    getCopyright() {
        //console.log(copyRights_id)
        this.contentfulservice.getdatapreview(this.copyRights_id).subscribe(res => {
            this.copyRights = res.fields.copyright
            // console.log(this.copyRights)
        })
    }

    getPrescribe() {
        this.presid = this.resourcedatares.fields.isi.sys.id;
        this.contentfulservice.getdatapreview(this.presid).subscribe(res => {
            if (res.fields.hasOwnProperty("safetyInformation")) {
                this.prescribe = res.fields.safetyInformation;
                //console.log(this.prescribe);
            } else { console.log("safetyInformation not exists") }
            if (res.fields.hasOwnProperty("shortStickyIsi")) {
                this.shortstickyisi = res.fields.shortStickyIsi
            } else { console.log("shortStickyIsi not exists") }
        })
    }

    getDynamicmsg() {
        if (this.sponserdherores.fields.hasOwnProperty("ogeqsaColumnRight")) {
            this.content_id2 = this.sponserdherores.fields.ogeqsaColumnRight.sys.id;
        } else { 
            // console.log("ogeqsaColumnRight not exists") 
        }
        //alert(this.content_id2)
        this.contentfulservice.getdatapreview(this.content_id2).subscribe(res => {
            let resourceform_id = res.fields.resourceForm.sys.id;
            this.contentfulservice.getdatapreview(resourceform_id).subscribe(res => {
                this.dynamicMsg = res.fields.heading
                //console.log(this.dynamicMsg)
            })
        })
    }

    getthankYouBox(){
        if(this.sponserdherores.fields.hasOwnProperty("thankYouBox")){
            this.thankYouBox = this.sponserdherores.fields.thankYouBox;
            let thankyou = this.sponserdherores.fields.thankYouBox;
            console.log(thankyou)
            
        } 
    }

    getreferences() {
        
        if (this.sponserdherores.fields.hasOwnProperty("mainTextBlock")) {
            let a = this.sponserdherores.fields.mainTextBlock.sys.id;
            this.references = this.sponserdherores.fields.references;
            let thankyou = this.sponserdherores.fields.thankYouBox;
            console.log(thankyou)
            this.contentfulservice.getdatapreview(a).subscribe(res => {
                this.copyBlockFootnotes = res.fields.copyBlockFootnotes;
            })
            console.log(this.references)
        } else { console.log("mainTextBlock not exists") }
       
    }

    getColor() {

        //console.log("works!")
        this.contentfulservice.getdatapreview(this.landingpageid).subscribe(res => {
            let brandresources = res.fields.sponsoredHero.sys.id;
            this.contentfulservice.getdatapreview(brandresources).subscribe(res => {
                if (res.fields.hasOwnProperty("branding")) {
                    let branding = res.fields.branding.sys.id;
                    this.contentfulservice.getdatapreview(branding).subscribe(res => {
                        if (res.fields.hasOwnProperty("primaryColor")) { this.resourceprimarycolor = res.fields.primaryColor.value; } else { console.log("resourceprimarycolor not exists") }
                        if (res.fields.hasOwnProperty("secondaryColor")) { this.resourcesecondarycolor = res.fields.secondaryColor.value; } else { console.log("resourcesecondarycolor not exists") }
                        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor1")) { this.gradient1 = res.fields.brandHeaderBackgroundGradientColor1.value } else { console.log("brandHeaderBackgroundGradientColor1 not exists") }
                        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor2")) { this.gradient2 = res.fields.brandHeaderBackgroundGradientColor2.value } else { console.log("brandHeaderBackgroundGradientColor2 not exists") }
                        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor3")) { this.gradient3 = res.fields.brandHeaderBackgroundGradientColor3.value } else { console.log("brandHeaderBackgroundGradientColor3 not exists") }
                        if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor1")) { this.footgradient1 = res.fields.brandFooterBackgroundGradientColor1.value } else { console.log("brandFooterBackgroundGradientColor1 not exists") }
                        if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor2")) { this.footgradient2 = res.fields.brandFooterBackgroundGradientColor2.value } else { console.log("brandFooterBackgroundGradientColor2 not exists") }
                        if (res.fields.hasOwnProperty("fontColor")) { this.resourcefontColor = res.fields.fontColor.value } else { console.log("resourcefontColor not exists") }
                        if (res.fields.hasOwnProperty("fontLinkColor")) { this.resourcefontLinkColor = res.fields.fontLinkColor.value } else { console.log("fontLinkColor not exists") }
                        if (res.fields.hasOwnProperty("fontLinkRolloverColor")) { this.resourcefontLinkRolloverColor = res.fields.fontLinkRolloverColor.value } else { console.log("fontLinkRolloverColor not exists") }
                        if (res.fields.hasOwnProperty("h1Color")) { this.resourceh1color = res.fields.h1Color.value } else { console.log("h1Color not exists") }
                        if (res.fields.hasOwnProperty("horizontalRule")) { this.resourcehorizontalRule = res.fields.horizontalRule.value } else { console.log("horizontalRule not exists") }
                        if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
                        if (res.fields.hasOwnProperty("buttonBackgroundColor")) { this.resourcebuttonBackgroundColor = res.fields.buttonBackgroundColor.value } else { console.log("buttonBackgroundColor not exists") }
                        if (res.fields.hasOwnProperty("buttonFontColor")) { this.resourcebuttonFontColor = res.fields.buttonFontColor.value } else { console.log("buttonFontColor not exists") }
                        if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
                        if (res.fields.hasOwnProperty("buttonRolloverFontColor")) { this.resourcebuttonRolloverFontColor = res.fields.buttonRolloverFontColor.value } else { console.log("buttonRolloverFontColor not exists") }
                        if (res.fields.hasOwnProperty("brandFooterBackgroundColor")) { this.resourcebrandFooterBackgroundColor = res.fields.brandFooterBackgroundColor.value } else { console.log("brandFooterBackgroundColor not exists") }

                        if (res.fields.hasOwnProperty("headerIndicationHeaderFontSize")) { this.headerIndicationHeaderFontSize = res.fields.headerIndicationHeaderFontSize } else { console.log("headerIndicationHeaderFontSize not exists") }
                        if (res.fields.hasOwnProperty("headerIndicationCopyFontSize")) { this.headerIndicationCopyFontSize = res.fields.headerIndicationCopyFontSize } else { console.log("headerIndicationCopyFontSize not exists") }
                        if (res.fields.hasOwnProperty("headerIndicationFontColor")) { this.headerIndicationFontColor = res.fields.headerIndicationFontColor.value } else { console.log("headerIndicationFontColor not exists") }
                        if (res.fields.hasOwnProperty("isiHeadersFontSize")) { this.isiHeadersFontSize = res.fields.isiHeadersFontSize } else { console.log("isiHeadersFontSize not exists") }
                        if (res.fields.hasOwnProperty("isiHeadersColors")) { this.isiHeadersColors = res.fields.isiHeadersColors.value } else { console.log("isiHeadersColors not exists") }
                        /*this.resourceh2color = res.fields.h2Color.value
                        this.resourceh3color = res.fields.h3Color.value
                        this.resourcebuttonBackgroundActiveColor = res.fields.buttonBackgroundActiveColor.value
                        this.resourcebuttonActiveFontColor = res.fields.buttonActiveFontColor.value
                        this.resourcefontLink = res.fields.fontLink.value
                        this.resourcefontFamily = res.fields.fontFamily*/
                        document.documentElement.style.setProperty('--resourceprimarycolor', this.resourceprimarycolor ? this.resourceprimarycolor : "#3254a2");
                        document.documentElement.style.setProperty('--resourcesecondarycolor', this.resourcesecondarycolor ? this.resourcesecondarycolor : "#691c32");
                        document.documentElement.style.setProperty('--gradient1', this.gradient1);
                        document.documentElement.style.setProperty('--gradient2', this.gradient2);
                        document.documentElement.style.setProperty('--gradient3', this.gradient3);
                        document.documentElement.style.setProperty('--footgradient1', this.footgradient1);
                        document.documentElement.style.setProperty('--footgradient2', this.footgradient2);
                        document.documentElement.style.setProperty('--resourceLinkColor', this.resourcefontLinkColor ? this.resourcefontLinkColor : "#3254a2");
                        document.documentElement.style.setProperty('--resourceLinkRolloverColor', this.resourcefontLinkRolloverColor ? this.resourcefontLinkRolloverColor : "#691c32");
                        document.documentElement.style.setProperty('--resourceh1Color', this.resourceh1color ? this.resourceh1color : "#3254a2");
                        document.documentElement.style.setProperty('--resourcehorizontalRuleColor', this.resourcehorizontalRule ? this.resourcehorizontalRule : "#3254a2");
                        document.documentElement.style.setProperty('--resourcebuttonBackgroundRollOverColor', this.resourcebuttonBackgroundRollOverColor ? this.resourcebuttonBackgroundRollOverColor : "#691c32");
                        document.documentElement.style.setProperty('--resourcebuttonfontcolor', this.resourcebuttonFontColor ? this.resourcebuttonFontColor : "#ffffff");
                        document.documentElement.style.setProperty('--resourcebuttonhoverfontcolor', this.resourcebuttonRolloverFontColor ? this.resourcebuttonRolloverFontColor : "#ffffff");
                        document.documentElement.style.setProperty('--footergradientColor', this.resourcebrandFooterBackgroundColor ? this.resourcebrandFooterBackgroundColor : "#3254a2");




                        document.documentElement.style.setProperty('--headerIndicationHeaderFontSize', this.headerIndicationHeaderFontSize ? this.headerIndicationHeaderFontSize : "19px");
                        document.documentElement.style.setProperty('--headerIndicationCopyFontSize', this.headerIndicationCopyFontSize ? this.headerIndicationCopyFontSize : "18px");
                        document.documentElement.style.setProperty('--headerIndicationFontColor', this.headerIndicationFontColor ? this.headerIndicationFontColor : "#ffffff");
                        document.documentElement.style.setProperty('--isiHeadersFontSize', this.isiHeadersFontSize ? this.isiHeadersFontSize : "1 REM");
                        document.documentElement.style.setProperty('--isiHeadersColors', this.isiHeadersColors ? this.isiHeadersColors : "#0072ce");

                        const gradientColors = [this.gradient1, this.gradient2, this.gradient3];
                        const gradientString = `linear-gradient(to right, ${gradientColors.join(", ")})`;
                        //document.documentElement.style.setProperty('--headergradientColor', "#b5b3b4");

                        document.getElementById("headgradient").style.background = gradientString;
                        // const footgradientColors = [this.footgradient1, this.footgradient2];
                        // const footgradientString = `linear-gradient(to right, ${footgradientColors.join(", ")})`;
                        // document.getElementById("footgradient").style.background = footgradientString;
                        // document.documentElement.style.setProperty('--headergradientColor', "#4b71c9");
                        document.getElementById("footgradient").style.background = this.resourcebrandFooterBackgroundColor;
                    })
                } else { console.log("branding not exists") }
            })
        })

    }

    getprescribingInformation() {
        if(this.sponserdherores.fields.hasOwnProperty('prescribingInformationContainer')){
        let a = this.sponserdherores.fields.prescribingInformationContainer.sys.id;
        this.contentfulservice.getdatapreview(a).subscribe(res => {
            if (res.fields.hasOwnProperty('prescribingInformationLink')) {
                this.p_file = res.fields.prescribingInformationLink;
                //console.log(this.p_file)
            } else {
                console.log("prescribingInformationPdf not exists")
            }
        })
    }
    }


}






