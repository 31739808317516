import { Component, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SideNavService } from 'src/app/core/components/main-core/side-nav/side-nav.service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  getImg:any

  constructor(private contentfulservice : ContentfulService, private sidebarService: SideNavService) { }

  ngOnInit() {
    this.getGraphicImg();

    this.sidebarService.subject$.subscribe((Details) => {
      console.log(Details);
      if(Details == true){
        document.getElementById("chatbot").style.zIndex = "0";
      }else{
        document.getElementById("chatbot").style.zIndex = "2";
      }
  })
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  private __getElementById(id: string): HTMLElement {
    console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  getGraphicImg(){
    this.contentfulservice.getAssets('/NrQqbpYDVRpR4xzgssc19/').subscribe(res=>{
      this.getImg = res.fields.file.url
    })
  }

 /* @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 1200){
      document.getElementById("chatbot").style.top = "80%"
    }
    else{
      document.getElementById("chatbot").style.top = "60%"
    }
  }*/

/*  @HostListener('window:scroll', ['$event'])
onWindowScroll($event) {
  const numb = window.scrollY;
  const chatBotEl = document.getElementById('chatbot');
  if (!chatBotEl) {
    return;
  }

  if (numb >= 1200) {
    chatBotEl.style.top = '80%';
  } else {
    const mediaQuerySm = window.matchMedia('(min-width: 576px)');
    const mediaQueryMd = window.matchMedia('(min-width: 768px)');
    const mediaQueryLg = window.matchMedia('(min-width: 992px)');
    const mediaQueryXl = window.matchMedia('(min-width: 1200px)');

    if (mediaQueryXl.matches) {
      chatBotEl.style.top = '60%';
    } else if (mediaQueryLg.matches) {
      chatBotEl.style.top = '55%';
    } else if (mediaQueryMd.matches) {
      chatBotEl.style.top = '50%';
    } else if (mediaQuerySm.matches) {
      chatBotEl.style.top = '45%';
    } else {
      chatBotEl.style.top = '35%';
    }
  }
}*/

@HostListener('window:scroll', ['$event'])
@HostListener('window:resize', ['$event'])
onWindowScrollOrResize($event) {
  const numb = window.scrollY;
  const chatBotEl = document.getElementById('chatbot');
  if (!chatBotEl) {
    return;
  }

  if (numb >= 1200) {
    chatBotEl.style.top = '80%';
  } else {
    const mediaQuerySm = window.matchMedia('(min-width: 576px)');
    const mediaQueryMd = window.matchMedia('(min-width: 768px)');
    const mediaQueryLg = window.matchMedia('(min-width: 992px)');
    const mediaQueryXl = window.matchMedia('(min-width: 1200px)');

    if (mediaQueryXl.matches) {
      chatBotEl.style.top = '55%';
    } else if (mediaQueryLg.matches) {
      chatBotEl.style.top = '50%';
    } else if (mediaQueryMd.matches) {
      chatBotEl.style.top = '45%';
    } else if (mediaQuerySm.matches) {
      chatBotEl.style.top = '40%';
    } else {
      chatBotEl.style.top = '30%';
    }
  }
}



}
