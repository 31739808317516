import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  isSidebarVisible: boolean = false;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor() { 
   // this.sidebarVisibilityChange.subscribe((value) => {
   //   this.isSidebarVisible = value;
 // });
  }

subject = new Subject<any>();
value: any;

get subject$(): Observable<any> {
  this.value = this.subject.asObservable();
  //console.log(this.value)
  return this.subject.asObservable();
}


 // toggleSidebarVisibility() {
 //   this.sidebarVisibilityChange.next(!this.isSidebarVisible);
 // }


}
