// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/core/interfaces/app-environment.interface';

export const environment: AppEnvironment = {
  production: false,
  baseApiUrl: 'https://cdn.contentful.com',
  baseImageUrl: 'https://images.ctfassets.net',
  baseFileURL: 'https://assets.ctfassets.net',
  baseMailUrl: 'https://api.ogesupport.com/v1/resources',
  previewUrl: 'https://preview.contentful.com',
  //siteKey:"6Lf3WEMjAAAAAMZ4UY1XwAn18XS5XaNswNtWc1pC",
  // siteKey:"6Lf3xWMkAAAAALOL7IyJsFvrFVOTVBGvAQIBj3QF",
  siteKey:"6Ld9ZkMjAAAAAMAuyzA1eZhscnqr2Un1rBmAHxiO",
  spaceId:'h2axairjfqha',
  accessToken:'Nr129JpVmw1hjQAv-zy1UM8Pk8vahuU228O0bUScylU',
  previewaccessToken:'Qv33QTCrNOKtcCHQkjibEc_6zPts0WML3cnV-xfS8SY',
  // baseAPI: PROXY_PREFIX_API_URL // using by http proxy,
  security: {
    allowedOrigins: 'https://cdn.contentful.com',
    // allowedOrigins: 'https://localhost:4200'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
