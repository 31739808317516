import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { FeatureRoutingModule } from './feature-routing.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { UserComponent } from './user/user.component';
import { UsersComponent } from './users/users.component';
import { AboutUsComponent } from './users/about-us/about-us.component';
import { InterventionMapComponent } from './users/intervention-map/intervention-map.component';
import { ResourceComponent } from './resource/resource.component';
import { SubresourceComponent } from './resource/subresource/subresource.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ChatBotComponent } from './users/chat-bot/chat-bot.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { BrowserModule, Title } from '@angular/platform-browser';
//import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
import { ContentfulService } from '../services/contentful.service';
import { QsaVideoComponent } from './qsa-video/qsa-video.component';
import { QsaGinaComponent } from './qsa-gina/qsa-gina.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../services/token-interceptor.service';
import { DiscountComponent } from './pharmacy/discount/discount.component';
import { ShowPdfComponent } from './show-pdf/show-pdf.component';
import { PreviewContentComponent } from './preview-content/preview-content.component';
import { LandingPagePreviewComponent } from './landing-page-preview/landing-page-preview.component';
import { ResourcePagePreviewComponent } from './resource-page-preview/resource-page-preview.component';
import { SubresourcePagePreviewComponent } from './resource-page-preview/subresource-page-preview/subresource-page-preview.component';
import { LandingPageSubresourceComponent } from './landing-page/landing-page-subresource/landing-page-subresource.component';
import { LandingPreviewPageSubresourceComponent } from './landing-page-preview/landing-preview-page-subresource/landing-preview-page-subresource.component';
// import { GoogleTagManagerService } from "angular-google-tag-manager";
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';





const componentParsers: Array<HookParserEntry> = [
  {
    component: ResourceComponent, 
  },
];

const dynamicComponents = [
  ResourceComponent, 
];

@NgModule({
  declarations: [UserComponent, UsersComponent,AboutUsComponent,
    InterventionMapComponent,ChatBotComponent, ResourceComponent, 
    SubresourceComponent, SubresourcePagePreviewComponent, LandingPageComponent, PrivacyPolicyComponent, 
    TermsOfUseComponent, PdfViewerComponent, PopUpComponent, ...dynamicComponents,
     QsaVideoComponent, QsaGinaComponent, ContactUsComponent, PharmacyComponent, DiscountComponent, ShowPdfComponent, PreviewContentComponent, LandingPagePreviewComponent, ResourcePagePreviewComponent, LandingPageSubresourceComponent, LandingPreviewPageSubresourceComponent],
     
  imports: [
    CommonModule,
    FeatureRoutingModule,
    AngularMaterialModule,
    NgxHideOnScrollModule,
    LandingPageModule,
    NgxCaptchaModule,
    RouterModule,
    FormsModule,ReactiveFormsModule,
    PdfViewerModule,
    ClipboardModule,
    //NgxContentfulRichTextModule,
    // GoogleTagManagerModule.forRoot({
    //   id: "GTM-TSRXCXP"
    // }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 3000,
    }),
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    }),
    // ToastNoAnimationModule.forRoot({
    //   timeOut: 1000,
    //   positionClass: 'toast-bottom-right',
    // }),
    // ToastNoAnimation
  ],
  entryComponents:[...dynamicComponents],
  providers :[...dynamicComponents, ContentfulService, Title, 
    {provide: HTTP_INTERCEPTORS, useClass:TokenInterceptorService, multi:true}
    //{provide: "googleTagManagerId", useValue: "GTM-TSRXCXP"}, GoogleTagManagerService
  ]
})
export class FeatureModule { }
