import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful.service';

import { AppController } from '../../../../app.controller';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AppController implements OnInit {
  resid: any;

  constructor(private breakpointObserver: BreakpointObserver,private contentfulservice: ContentfulService) {
    super();
  }
  

  ngOnInit() {

    //console.log(this.contentfulservice.ressID);
    
  }

  nav_position: string = 'end';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


 

}
