import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MergePdfService {


 
  /*async mergePDFs(pdfLinks: string[]): Promise<string> {
    const mergedPdf = await PDFDocument.create();
  
    for (const pdfLink of pdfLinks) {
      const pdfBytes = await fetch(pdfLink).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
  
    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
  
    return url;
  }*/

  
}
