import { Component, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  termsTitle: any;
  termsContent: any;


  constructor(private contentfulservice: ContentfulService) { }

  ngOnInit() {
    this.getPrivacyPolicy()
  }

  // getPrivacyPolicy(){
  //   this.contentfulservice.getdata('2gEeQbOLwqktRysF3YWIWA').subscribe(res =>{
  //     this.termsTitle = res.fields.internalName
  //     this.termsContent = res.fields.termsOfUse.content[0].content[0].value
  //   })
  // }

  public termsOfUse:any;
  getPrivacyPolicy(){
    this.contentfulservice.getdata('4H9VXSCgWZrCjA0gZt5LgA').subscribe(res =>{
      console.log(res.fields);
      this.termsOfUse = res.fields.copyBlock
    });

  }

  public options:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options);
    }
}

}
