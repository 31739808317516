import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourceComponent } from '../resource/resource.component';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import * as htmlToPdfmake from 'html-to-pdfmake';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


import html2canvas from 'html2canvas';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {



  datas: any;
  prescribes: any
  isPdfLoaded = false;
  private pdf: PDFDocumentProxy;
  private prescribePdf: PDFDocumentProxy;
  private fullpdf: PDFDocumentProxy;
  page: number = 1;
  page1: number = 1;
  totalPages: number = 0;
  totalPages1: number = 0;
  totalPages2: number = 0;
  isLoaded: boolean = false;
  isLoadeds: boolean = false;
  isLoadeds1: boolean = false;

  @ViewChild(ResourceComponent, { static: false }) childReference;
  arrayB: string | Uint8Array | ArrayBuffer;
  myfile: string;
  arrayurls: any;
  mergedpdfurl: string;
  totalofeachpdf: number[];
  pdfurls: any;
  viewpdfurl: string;


  constructor(private http: HttpClient) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    //console.log(localStorage.getItem('pdfData'))
    this.datas = JSON.parse(localStorage.getItem('pdfData'));
    this.prescribes = JSON.parse(localStorage.getItem('prescribe'))
    this.pdf = null;
    this.prescribePdf = null;
    this.isPdfLoaded = false;

    this.pdfurls = this.datas.concat(this.prescribes)
    console.log(this.prescribes)
    let filteredArr = this.pdfurls.filter(Boolean)

    const modifiedPdfUrls = filteredArr.map(url => {
      if (!url.startsWith('http:') && !url.startsWith('https:')) {
        return 'https:' + url;
      }
      return url;
    });

    console.log(modifiedPdfUrls)

    function replaceUrlPrefix(urls: string[]): string[] {
      // const newUrls = urls.map(url => url.replace('https://', 'https://api.mosyproxy.tk/'));
      const newUrls = urls.map(url => url.replace('https://', 'https://api.mosy.in/'));
      return newUrls;
    }

    let updatedUrls = replaceUrlPrefix(modifiedPdfUrls);
    console.log(updatedUrls);


    let finalurl = this.getValidPdfUrls(updatedUrls)
    console.log(finalurl)

    const PDFMerger = require('pdf-merger-js');
    var merger = new PDFMerger();
    (async () => {
      for (const file of finalurl) {
        await merger.add(file)
      }
      const mergedPdf = await merger.saveAsBlob();
      const url = URL.createObjectURL(mergedPdf);
      console.log(url)
      this.viewpdfurl = url
    })();



    
  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.fullpdf = pdf;
    this.isPdfLoaded = true;
  }

  print() {
    this.fullpdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });
      const blobUrl = window.URL.createObjectURL((blob));
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    });
  }

 afterLoadComplete(pdf: any) {
    this.totalPages = pdf.numPages;
    this.isLoaded = true;
  }
  afterLoadCompleteInfo(pdfData: any) {
    this.totalPages1 = pdfData.numPages;
    this.isLoadeds = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  nextPageP() {
    this.page1++;
  }

  prevPageP() {
    this.page1--;
  }

  // printPdf(){
  //   print({printable:'n.pdf', showModal:true})
  // }


  getValidPdfUrls(urls: string[]): string[] {
    const validPdfUrls: string[] = [];

    urls.forEach((url) => {
      if (url.toLowerCase().endsWith('.pdf')) {
        validPdfUrls.push(url);
      } else {
        console.log("executed for", url)
        htmlToPdfmake(url, (pdfDoc) => {
          console.log("executed for", url)
          const blob = new Blob([pdfDoc], { type: 'application/pdf' });
          const convertedUrl = URL.createObjectURL(blob);
          validPdfUrls.push(convertedUrl);
        });
      }
    });

    return validPdfUrls;
  }



}



