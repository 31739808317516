import { HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenserviceService } from './tokenservice.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  access: any
  constructor(private injector:Injector ) { }
  token
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   

    this.token =  localStorage.getItem('Bearer ')
    let bearerToken = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.token
      }
    })
    return next.handle(bearerToken)
  }
  
}
