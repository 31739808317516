import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { SideNavComponent } from './components/main-core/side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularMaterialModule } from '../angular-material.module';
import { MainCoreComponent } from './components/main-core/main-core.component';
import { FooterComponent } from './components/main-core/footer/footer.component';
import { HeaderComponent } from './components/main-core/header/header.component';
import { HomeComponent } from './components/main-core/home/home.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    AngularMaterialModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SideNavComponent,
    MainCoreComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    MainCoreComponent
  ]
})
export class CoreModule {
}
