import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ContentfulService } from 'src/app/services/contentful.service';
import { SideNavService } from './side-nav.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0,0,0)',
          opacity:1,
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
          opacity:0,
        })
      ),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('1300ms ease-in-out')),
    ]),
  ],
})
export class SideNavComponent implements OnInit {
  nav_position: string = 'end';
  logo: string
  opened: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  drugdata: any;
  versionone_med: any;
  versionone_medname: any;
  result2: any;
  resid: any;
  resourceres: any;
  landingpageid: any;



  constructor(private breakpointObserver: BreakpointObserver, private contentfulservice: ContentfulService, private sidebarService: SideNavService) {
    
    

  }

  ngOnInit():void{
    
    this.getHizentraimage();
    // this.getMenuDetails();
    this.getMenu2Details();

    
    let url = window.location.href
    let landingurlword = (url.substring(url.lastIndexOf('/') + 1))
    let wordinurl = (url.substring(0, url.lastIndexOf('/')));
    let resourceurl = (wordinurl.substring(0, wordinurl.lastIndexOf('/')));
    let wordinurl2 = (wordinurl.substring(wordinurl.lastIndexOf('/') + 1));
    let resourceurlword = (resourceurl.substring(resourceurl.lastIndexOf('/') + 1));
    let a = wordinurl2;
    let b = resourceurlword
    //console.log(a);
    //console.log(b);

    
    
    if (b == "resource") {
      //console.log("Yes resource Page")
      this.contentfulservice.getVersiononeMedicines().subscribe(res => {
        this.drugdata = res;
        this.versionone_med = res.results.map(item => {
          return item.name;
        });
        
        this.versionone_medname = (filterItems(this.versionone_med, wordinurl2))
        this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
        this.resid = this.result2.contentfulResourceId;
        console.log(this.resid);
        this.contentfulservice.getdatawithoutId(this.resid).subscribe(res => {
          this.resourceres = res;
          let nav_id = res.fields.navigation.sys.id;
          console.log(nav_id)
          this.contentfulservice.getdatawithoutId(nav_id).subscribe(res => {
            let headerstate = res.fields.internalName;
            console.log(headerstate)
            if(headerstate == "OEG Universal Navigation"){
              let a = res.fields.imageWrapper.sys.id
              this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                console.log(res.fields.internalName, res.fields.altText, res.fields.url)
               let logolink = res.fields.url
               document.getElementById('logimg').setAttribute('href', logolink);
               document.getElementById('logimg').style.cursor = "pointer";
              })
              //console.log("header exists");
              this.showhamburgermenu()
            }else{
              if(headerstate == "OGE Universal Navigation - NO HAMBURGER"){
                //console.log("no header")
                let a = res.fields.imageWrapper.sys.id
              this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                console.log(res.fields.internalName, res.fields.altText)
                document.getElementById('logimg').setAttribute('alt', res.fields.altText);
              });
              console.log(this.contentfulservice.getdatawithoutId(a),341234)
                this.hidehamburgermenu()
              }else{
                // console.log("header exists",24524252);
                this.showhamburgermenu()
              }
            }
        })

      })
    })
    }
    else {
      if (a == "home") {
        //console.log("homepage")
        this.contentfulservice.getVersiononeMedicines().subscribe(res => {
          this.drugdata = res;
          this.versionone_med = res.results.map(item => {
            return item.name;
          });
          
          this.versionone_medname = (filterItems(this.versionone_med, landingurlword))
          this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
          
          //console.log( landingurlword);
          this.landingpageid = this.result2.contentfulLandingPageId;
        this.contentfulservice.getdatawithoutId(this.landingpageid).subscribe(res => {
          let nav_id = res.fields.navigation.sys.id;
         // console.log(nav_id)
          this.contentfulservice.getdatawithoutId(nav_id).subscribe(res => {
            let headerstate = res.fields.internalName;
           // console.log(headerstate)
            if(headerstate == "OEG Universal Navigation"){
              //console.log("header exists");
               let a = res.fields.imageWrapper.sys.id 
              this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                console.log(res.fields.internalName, res.fields.altText, res.fields.url)
                let loglink = res.fields.url
                document.getElementById('logimg').setAttribute('href', loglink);
                document.getElementById('logimg').style.cursor = "pointer";
                document.getElementById('logoimgalt').setAttribute('alt', res.fields.altText);
              })
              this.showhamburgermenu()
            }else{
              if(headerstate == "OEG Universal Navigation - NO HAMBURGER"){
              //  console.log("no header")
              let a = res.fields.imageWrapper.sys.id
              this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                console.log(res.fields.internalName, res.fields.altText)
                document.getElementById('logoimgalt').setAttribute('alt', res.fields.altText);
              })
                this.hidehamburgermenu()
              }else{
              //  console.log("header exists");
                this.showhamburgermenu()
              }
            }
        })

      })
    })
      } else {
        console.log("OtherPage")
        this.showhamburgermenu()
      }
    }


    function filterItems(arr, query) {
      //return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
      return arr.filter(element => element.toLowerCase() === query.toLowerCase());
    }
  }

  ngAfterViewInit() {
    //console.log('  ChildComponent==>AfterViewInit');
    if (window.location.href.endsWith("pdfViewer")) {
      //console.log("pdfviwer")
      document.getElementById('element-to-hide').style.display = "none";
    }
  }

  hidehamburgermenu(){
    document.getElementById('sidenavButton').style.display = "none";
    document.getElementById('logimg').removeAttribute('href');
    document.getElementById('logimg').style.cursor = "default";
    document.getElementById('logoimgalt').setAttribute('alt', 'oge');

  }
  showhamburgermenu(){
    window.onload = function() {
      document.getElementById('sidenavButton').style.display = "block";
      // document.getElementById('logimg').setAttribute('href', 'http://ogesupport.com');
      document.getElementById('logimg').style.cursor = "pointer";
      document.getElementById('logoimgalt').setAttribute('alt', 'OGE');
    };
  }


  name = 'Angular';

  menuState: string = 'out';

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    //console.log(this.menuState);
    if(this.menuState == 'in'){
      //this.sidebarService.isSidebarVisible = true;
      //console.log(this.sidebarService.isSidebarVisible)
      this.sidebarService.subject.next(true); 
    }else{
     // this.sidebarService.isSidebarVisible = false;
     // console.log(this.sidebarService.isSidebarVisible)
      this.sidebarService.subject.next(false); 
    }
  }

  getHizentraimage(){
    this.contentfulservice.getAssets('/31lqK7ynnfTX0sTRjDH4bT/')
    .subscribe(
      imgData => this.logo = imgData.fields.file.url,
      err => console.log(err)
    );
    
  }

  // new = ''
  // newLink = ''
  // getMenuDetails(){
  //   this.contentfulservice.getdata('4BRqcClhx9uviPxboGVv6X').subscribe(res =>{
  //     this.new = res.fields.navigationText
  //     this.newLink = res.fields.navigationLink
  //   })
  // }

  about= ''
  aboutLink =''

  getMenu2Details(){
    this.contentfulservice.getdata('4sLeQiwclWW965Jo60YMO0').subscribe(res =>{
      this.about = res.fields.navigationText
      this.aboutLink = res.fields.navigationLink
    })
  }


 
} 
