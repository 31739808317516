import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyTitle: any;
  privacyContent: any;

  constructor(private contentfulservice: ContentfulService, private titleService: Title) { }

  ngOnInit() {
    this.getPrivacyPolicy()
  }


  public privacy:any;
  getPrivacyPolicy(){
    this.contentfulservice.getdata('3Kf6H1HZnsrAwVc07wHJuX').subscribe(res =>{
      //this.titleService.setTitle(res.fields.pageTitle)
      console.log(res.fields);
      this.privacy = res.fields.copyBlock
    }); 
  }

  public options:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options);
    }
}

}
