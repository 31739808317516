import { Component, HostListener, OnInit } from '@angular/core';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';
import { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
@Component({
  selector: 'app-subresource-page-preview',
  templateUrl: './subresource-page-preview.component.html',
  styleUrls: ['./subresource-page-preview.component.scss']
})
export class SubresourcePagePreviewComponent implements OnInit {

  responseData: any[] | undefined = [];
  subResourceContent = [];
  resourcespaceID: number;
  isi_id: any;
  resid: any;
  resid2: any;
  resourceprimarycolor: any;
  resourcetertiaryColor: any;
  resourcesecondarycolor: any;
  resourcefontColor: any;
  resourcehorizontalRule: any;
  indication_header: any;
  isi_header: any;
  indication_text: any;
  isi_text: any;
  headerIndicationHeaderFontSize: any;
  headerIndicationCopyFontSize: any;
  headerIndicationFontColor: any;
  isiHeadersFontSize: any;
  isiHeadersColors: any;
  drugdata: any;
  versionone_med: any;
  ogeUrl: string;
  versionone_medname: any;
  result2: any;
  drug: any;



  constructor(private contentfulservice: ContentfulService) {


    // let url = window.location.href
    // let wordinurl = (url.substring(url.lastIndexOf('/') + 1));
    // this.resid = wordinurl;

    //  this.contentfulservice.getVersiononeMedicines().subscribe(res => {
    //     this.drugdata = res;
    //     this.versionone_med = res.results.map(item => {
    //       return item.contentfulResourceId;
    //     });

    //     this.versionone_med = this.versionone_med.filter(function( element ) {
    //       return element !== undefined;
    //    });

    //     let str = window.location.href;
    //     this.ogeUrl = str;
    //     let urlmatch = (str.substring(str.lastIndexOf('/') + 1));
    //     this.versionone_medname = (filterItems(this.versionone_med, urlmatch))
    //     this.result2 = this.drugdata.results.find(item => item.contentfulResourceId === this.versionone_medname[0]);
    //     this.drug = this.result2.name;
    //     this.resid = this.result2.contentfulResourceId;
    //     this.contentfulservice.resid_Visibility(this.resid)
    //   //  this.landingpageid = this.result2.contentfulLandingPageId;
    //   //  console.log(this.resid, this.landingpageid)

    //  this.getshortstickIsiwithoutid(this.resid);

    //  this.getColor();

    // })
    // function filterItems(arr, query) {
    //     //return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
    //     return arr.filter(element => element.toLowerCase() === query.toLowerCase());
    //   }

    let str = window.location.href;
    this.ogeUrl = str;
    let wordinurl = (this.ogeUrl.substring(this.ogeUrl.lastIndexOf('/') + 1));
    console.log(wordinurl)
    this.resid = wordinurl
    console.log(this.resid)

    this.getshortstickIsiwithoutid(this.resid);

    this.getColor();

  }

  offsetFlag = true;
  mainText: Observable<[]>;
  heading: Observable<[]>;
  paragraph: Observable<[]>;
  unorderedList: Observable<[]>;
  listItem: Observable<[]>;
  listItem1: Observable<[]>;
  subhead: Observable<[]>;
  mainTexts
  shortStickyIsi: any;

  ngOnInit() {

    // window.scrollY = 0;
    // this.getSubResource();
    //this.getContent();
    //this.getShortStickIsi();
    //this.resid = this.contentfulservice.getTest();
    // alert(this.resid);


    //this.getshortstickIsiforresourcepage(this.resid2);


  }



  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth"
    });
  }

  private getElementById(id: string): HTMLElement {
    //console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.getElementById(id);
    this.scrollToElement(element);
  }

  /*
    @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
      // console.log(window.pageYOffset, event);
      if(window.pageYOffset > 530 )
        this.offsetFlag = false;
      else
        this.offsetFlag = true;
    }
  */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 1200) {
      this.offsetFlag = false;
    } else {
      this.offsetFlag = true;
    }
  }


  // getShortStickIsi(){
  //   this.contentfulservice.getdatapreview( this.isi_id).subscribe(res =>{
  //   console.log(res.fields);
  //   this.shortStickyIsi = res.fields.shortStickyIsi
  //});
  //}

  getshortstickIsiwithoutid(value) {
    this.contentfulservice.getdatapreview(value).subscribe(res => {
      //console.log("StickyIsiId : ", res.fields.isi.sys.id)
      this.isi_id = res.fields.isi.sys.id;
      this.contentfulservice.getdatapreview(this.isi_id).subscribe(res => {
        // console.log(res.fields);
        // this.shortStickyIsi = res.fields.shortStickyIsi

        if (res.fields.hasOwnProperty('shortStickyIsiIndicationsHeader')) { this.indication_header = res.fields.shortStickyIsiIndicationsHeader; }
        if (res.fields.hasOwnProperty('shortStickyIsiSafetyInformationHeader')) { this.isi_header = res.fields.shortStickyIsiSafetyInformationHeader; }
        if (res.fields.hasOwnProperty('shortStickyIsiIndicationsText')) { this.indication_text = res.fields.shortStickyIsiIndicationsText; }
        if (res.fields.hasOwnProperty('shortStickyIsiImportantSafetyInformationText')) { this.isi_text = res.fields.shortStickyIsiImportantSafetyInformationText }
      });
    })
  }

  /*getshortstickIsiforresourcepage(value) {
      console.log("for resource id", value)
      this.contentfulservice.getdatapreview(value).subscribe(res => {
          //console.log("StickyIsiId : ", res.fields.isi.sys.id)
          this.isi_id = res.fields.isi.sys.id;
          this.contentfulservice.getdatapreview(this.isi_id).subscribe(res => {
              //console.log(res.fields);
              this.shortStickyIsi = res.fields.shortStickyIsi

              this.indication_header = res.fiels.shortStickyIsiIndicationsHeader;
              this.isi_header = res.fiels.shortStickyIsiSafetyInformationHeader;
              this.indication_text = res.fields.shortStickyIsiIndicationsText;
              this.isi_text = res.fields.shortStickyIsiImportantSafetyInformationText

          });
      })
  }*/

  public options: any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading</p>';
    } else {
      return documentToHtmlString(richText, this.options);
    }
  }

  //getContent(){
  //   this.contentfulservice.getdatapreview('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
  //this.contentfulservice.getdatapreview('60ilGfimhTUiGmXOxai0n9').subscribe(res =>{
  //  this.mainText = res.fields.prescribingInformationCopy.content[0].content[0].value;
  //console.log(res)
  //  })
  //}

  // masterContent:any;
  // getAllContents(){
  //   this.contentfulservice.getAlldata().subscribe(res =>{
  //     this.masterContent = res.items;
  //     this.getShortSticky();
  //   });
  // }

  // getShortSticky(){
  //   this.contentfulservice.getdatapreview('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
  //     res.fields.shortStickyIsi.content.forEach((ele)=>{
  //       ele.content.forEach((elem)=>{
  //         this.subResourceContent = elem
  //         // console.log(this.subResourceContent)
  //       })
  //     })
  //   })
  // }

  getColor() {

    //console.log("works!")
    this.contentfulservice.getdatapreview(this.resid).subscribe(res => {
      let brandresources = res.fields.branddiseaseResources.sys.id;
      this.contentfulservice.getdatapreview(brandresources).subscribe(res => {
        if (res.fields.hasOwnProperty("branding")) {
          let branding = res.fields.branding.sys.id;
          this.contentfulservice.getdatapreview(branding).subscribe(res => {
            if (res.fields.hasOwnProperty("primaryColor")) { this.resourceprimarycolor = res.fields.primaryColor.value; } else { console.log("resourceprimarycolor not exists") }
            if (res.fields.hasOwnProperty("tertiaryColor")) { this.resourcetertiaryColor = res.fields.tertiaryColor.value; } else { console.log("tertiaryColor not exists") }
            if (res.fields.hasOwnProperty("secondaryColor")) { this.resourcesecondarycolor = res.fields.secondaryColor.value; } else { console.log("resourcesecondarycolor not exists") }
            if (res.fields.hasOwnProperty("fontColor")) { this.resourcefontColor = res.fields.fontColor.value } else { console.log("resourcefontColor not exists") }
            if (res.fields.hasOwnProperty("horizontalRule")) { this.resourcehorizontalRule = res.fields.horizontalRule.value } else { console.log("horizontalRule not exists") }

            if (res.fields.hasOwnProperty("headerIndicationHeaderFontSize")) { this.headerIndicationHeaderFontSize = res.fields.headerIndicationHeaderFontSize } else { console.log("headerIndicationHeaderFontSize not exists") }
            if (res.fields.hasOwnProperty("headerIndicationCopyFontSize")) { this.headerIndicationCopyFontSize = res.fields.headerIndicationCopyFontSize } else { console.log("headerIndicationCopyFontSize not exists") }
            if (res.fields.hasOwnProperty("headerIndicationFontColor")) { this.headerIndicationFontColor = res.fields.headerIndicationFontColor.value } else { console.log("headerIndicationFontColor not exists") }
            if (res.fields.hasOwnProperty("isiHeadersFontSize")) { this.isiHeadersFontSize = res.fields.isiHeadersFontSize } else { console.log("isiHeadersFontSize not exists") }
            if (res.fields.hasOwnProperty("isiHeadersColors")) { this.isiHeadersColors = res.fields.isiHeadersColors.value } else { console.log("isiHeadersColors not exists") }

            document.documentElement.style.setProperty('--resourceprimarycolor', this.resourceprimarycolor ? this.resourceprimarycolor : "#3254a2");
            document.documentElement.style.setProperty('--resourcesecondarycolor', this.resourcesecondarycolor ? this.resourcesecondarycolor : "#691c32");
            document.documentElement.style.setProperty('--resourcehorizontalRuleColor', this.resourcehorizontalRule ? this.resourcehorizontalRule : "#3254a2");

            document.documentElement.style.setProperty('--headerIndicationHeaderFontSize', this.headerIndicationHeaderFontSize ? this.headerIndicationHeaderFontSize : "19px");
            document.documentElement.style.setProperty('--headerIndicationCopyFontSize', this.headerIndicationCopyFontSize ? this.headerIndicationCopyFontSize : "18px");
            document.documentElement.style.setProperty('--headerIndicationFontColor', this.headerIndicationFontColor ? this.headerIndicationFontColor : "#ffffff");
            document.documentElement.style.setProperty('--isiHeadersFontSize', this.isiHeadersFontSize ? this.isiHeadersFontSize : "1 REM");
            document.documentElement.style.setProperty('--isiHeadersColors', this.isiHeadersColors ? this.isiHeadersColors : "#0072ce");
          })
        } else { console.log("branding not exists") }
      })
    })

  }


}
