import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entry } from 'contentful';
import { ClipboardService } from 'ngx-clipboard';
import { forkJoin, from, Observable, of, throwError, timer, zip } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MatDialog } from '@angular/material/dialog';
import { RELATIVE_TEXT } from './emailbody';
import { ToastrService } from 'ngx-toastr';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { catchError, concatMap, delay, delayWhen, mergeMap, retryWhen, take, timeout, timeoutWith } from 'rxjs/operators';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfmake from 'html-to-pdfmake';
import { MergePdfService } from 'src/app/services/merge-pdf.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {
  @ViewChild('closebutton', {
    static: false
  }) closebutton;
  hideToggle = false;
  heading: Observable<[]>;
  subhead: Observable<[]>;
  copyRights: Observable<[]>;
  landFootImg: String;
  logo: String;
  dynamicMsg: any;
  paraContent: any;
  checked: boolean = false;
  pdfValues: any = [];
  myemailForm!: FormGroup
  provideForm!: FormGroup
  submitted = false;
  firstList: any;
  firstListUrl: any;
  firstListValue: any;
  firstListUrlValue: any;
  secondListValue: any;
  secondList: any;
  secondListUrl: any;
  secondListValue2: any;
  secondListValue2Url: any;
  secondListValue2Sl: any;
  secondListValue2Tl: any;
  secondListValue2Fl: any;
  secondListValue2TlUrl: any;
  thirdList: any;
  resourceErrorMessage: "Please select any resource"
  reqId: any
  getfile: any
  routeListener
  userId
  panelOpenState = false;
  indication: any;
  isi: any;
  resid: any;
  isi_id: any;
  dynamicmssg_id: any;
  branddiseaseResources_id: any;
  pageTitle_id: any;
  drugdata: any;
  versionone_med: any;
  versionone_medname: any;
  result2: any;
  resid2: any;
  seotitle: any;
  seodes: any;
  hidePagesFromSearchEnginesNoindex: any;
  excluedLinksFromSearchRankingsNofollow: any;
  pagekeywords: any;
  prescribe_id: any;
  presinfo_id: any;
  copyRights_id: any;
  paraContent_id: any;
  dymssg_id: any;
  dynamicMsg2_id: any;
  icon_id: any;
  icon1_id: any;
  icon2_id: any;
  heading_id: any;
  list_id: any;
  list1_id: any;
  list2_id: any;
  list3_id: any;
  brandheader_id: any;
  ogeUrl: string;
  drug: string;
  resourceres: any;
  brandresourceres: any;
  dynamicmssgres: any;
  footerres: any;
  logoimgid: any;
  shortstickyisi: any;
  gradient1: any;
  gradient2: any;
  gradient3: any;
  gradientrotation: void;
  gradientrotation2: any;
  resourceprimarycolor: any;
  resourcesecondarycolor: any;
  resourcetertiaryColor: any;
  footgradient1: any;
  footgradient2: any;
  footgradientrotation2: any;
  resourceh1color: any;
  resourceh2color: any;
  resourceh3color: any;
  resourcehorizontalRule: any;
  resourcebuttonBackgroundColor: any;
  resourcebuttonFontColor: any;
  resourcebuttonBackgroundRollOverColor: any;
  resourcebuttonRolloverFontColor: any;
  resourcebuttonActiveFontColor: any;
  resourcebuttonBackgroundActiveColor: any;
  resourcefontColor: any;
  resourcefontLinkColor: any;
  resourcefontLinkRolloverColor: any;
  resourcefontLink: any;
  resourcefontFamily: any;
  realtedbuttonids: any;
  button1name: any;
  button1link: any;
  button1textcol: any;
  button1backcol: any;
  button2name: any;
  button2link: any;
  button2textcol: any;
  button2backcol: any;
  relatedbuttonarray: any[];
  presinternalname: any;
  p_file: any;
  resourcebrandFooterBackgroundColor: any;
  resourcebrandFooterFontColor: any;
  resourceHeaderColor: any;
  headerIndicationHeaderFontSize: any;
  headerIndicationCopyFontSize: any;
  headerIndicationFontColor: any;
  isiHeadersFontSize: any;
  isiHeadersColors: any;
  ginacontent: any;
  pdfUrls: string[] = [];
  convertedUrls: string[] = [];
  mergedPdfUrl: Promise<string>;
  viewpdfurl: string;
  provideemailbody: any;
  indication_text: any;
  emailcustomLink: string;
  provideemailsubject: any;
  isiTextFontSize: any;
  bodyTextFontSize: any;
  bodyTextFontWeight: any;
  isiTextFontWeight: any;
  bodyTextLineHeight: any;
  isiTextLineHeight: any;
  linkIcon: any;
  copyIcon: any;
  searchIcon: any;
  getStartedContent: any;
  indicationTitle: any;
  constructor(private contentfulservice: ContentfulService, private router: Router,
    private clipboardService: ClipboardService, private http: HttpClient,
    private dialogRef: MatDialog, private toast: ToastrService, private route: ActivatedRoute,
    private fb: FormBuilder, private titleService: Title, private metaservice: Meta, private mergeService: MergePdfService) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.getallpageapicalls()
  }
  ngOnInit() {
    document.getElementById("head-content").scrollIntoView();
    this.myemailForm = this.fb.group({
      fromEmail: ['support@ogesupport.com'],
      toEmail: ['', [Validators.required]],
      subject: ['A resource has been shared with you'],
      body: ['']
    })
    this.routeListener = this.route.params.subscribe(params => {
      this.userId = params['id'];
    });
    this.provideForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      //id: new FormControl(`${this.userId}`, [Validators.required]),
      ogeUrl: new FormControl('Resource Url'),
      drug: new FormControl('Drug Name'),
      sessionId: new FormControl(`${this.userId}`),
      body: new FormControl('Mail Content'),
      fromEmail: new FormControl('support@ogesupport.com'),
      subject: new FormControl('')
    });
    console.log('Initial Subject Value:', this.provideForm.get('subject').value);
  }
  getallpageapicalls() {
    this.contentfulservice.getVersiononeMedicines().subscribe(
      (res) => {
        this.drugdata = res;
        this.versionone_med = res.results.map(item => item.name);
        const url = window.location.href;
        this.ogeUrl = url;
        const wordinurl = (url.substring(0, url.lastIndexOf('/')));
        const wordinurl2 = (wordinurl.substring(wordinurl.lastIndexOf('/') + 1));
        this.drug = wordinurl2;
        this.versionone_medname = (filterItems(this.versionone_med, wordinurl2))
        this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
        if (this.result2 !== undefined) {
          this.resid2 = this.result2.contentfulResourceId;
          document.getElementById('top').style.display = "block"
          document.getElementById('alertmssg').style.display = "none"
        } else {
          document.getElementById('top').style.display = "none"
          document.getElementById('alertmssg').style.display = "block"
        }
        this.resid = this.resid2;
        console.log(this.resid);
        this.contentfulservice.resid_Visibility(this.resid2);
        this.contentfulservice.getdatawithoutId(this.resid).pipe(this.retryWithDelay()).subscribe(
          (data) => {
            console.log(data);
            this.resourceres = data;
            this.processData();
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );

    function filterItems(arr, query) {
      return arr.filter(element => element.toLowerCase() === query.toLowerCase());
    }
  }
  processData() {
    try {
      this.getSeoData();
      this.getAllContents();
      this.getInformation();
      this.getPrescribe();
      this.getprovideemailbody();
      this.getAllDynamicMssg();
      this.getbrandresource();
    } catch (error) {
      console.error('Error processing data:', error);
    }
  }
  getSeoData() {
    this.pageTitle_id = this.resourceres.sys.id;
    this.contentfulservice.getdata(this.pageTitle_id).subscribe(res => {
      if (res.fields.hasOwnProperty("seoMetadata")) {
        let seoid = res.fields.seoMetadata.sys.id
        this.titleService.setTitle(res.fields.pageTitle)
        this.contentfulservice.getdata(seoid).subscribe(res => {
          this.seotitle = res.fields.seoTitle;
          this.seodes = res.fields.seoDescription;
          this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
          this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
          this.pagekeywords = res.fields.keywords;
          this.metaservice.addTag({
            name: 'description',
            content: this.seodes
          });
          this.metaservice.addTag({
            name: 'application-name',
            content: this.seotitle
          });
          this.metaservice.addTag({
            name: 'keywords',
            content: this.pagekeywords
          });
          this.metaservice.addTag({
            name: 'noindex',
            content: this.hidePagesFromSearchEnginesNoindex
          });
          this.metaservice.addTag({
            name: 'nofollow',
            content: this.excluedLinksFromSearchRankingsNofollow
          });
        })
      }
    })
  }
  masterContent: any;
  getAllContents() {
    this.contentfulservice.getAlldata().subscribe(res => {
      this.masterContent = res.items;
      this.getResourceContent();
    });
  }
  resourceContents = [];
  getResourceContent() {
    if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
      this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
      this.contentfulservice.getdata(this.branddiseaseResources_id).subscribe(res => {
        res.fields.healthToolsOnOgeqsa.forEach((element) => {
          let index = this.masterContent.filter(data => data.sys.id == element.sys.id);
          if (index) {
            this.resourceContents.push(index[0]);
            if (res.fields.healthToolsOnOgeqsa.length == this.resourceContents.length) {
              this.groupResources();
            }
          }
        });
      })
    } else {
      console.log("branddiseaseResources not exsits")
    }
  }
  resourceData: any;
  groupResources() {
    var filtered = this.resourceContents.filter(function (x) {
      return x !== undefined;
    });
    var groups = new Set(filtered.map(item => item.fields.audiences.sys.id));
    this.resourceData = [];
    groups.forEach(g =>
      this.resourceData.push({
        name: g,
        values: filtered.filter(i => i.fields.audiences.sys.id === g)
      }))
    if (groups.size == this.resourceData.length) {
      this.resourceData.forEach(resource => {
        this.getResourceAudianceTitle(resource.name).then((groupName) => {
          resource.name = groupName;
        });
      });
    }
  }
  getResourceAudianceTitle(id) {
    let that = this;
    return new Promise(function (resolve, reject) {
      that.contentfulservice.getdata(id).subscribe(res => {
        resolve(res.fields.audience);
      }, err => {
        reject(console.log(err));
      });
    });
  }
  public safetyInformation: any;
  public prescribePdf: any;
  getInformation() {
    if (this.resourceres.fields.hasOwnProperty("isi")) {
      this.isi_id = this.resourceres.fields.isi.sys.id;
    } else {
      console.log("isi not exsits")
    }
    this.contentfulservice.getdata(this.isi_id).subscribe(res => {
      if (res.fields.hasOwnProperty('shortStickyIsiIndicationsText')) {
        this.indication_text = res.fields.shortStickyIsiIndicationsText;
      }
      if (res.fields.hasOwnProperty("safetyInformation")) {
        this.isi = res.fields.safetyInformation
      } else {
        console.log("safetyInformation not exsits")
      }
      if (res.fields.hasOwnProperty("shortStickyIsi")) {
        this.shortstickyisi = res.fields.shortStickyIsi
      } else {
        console.log("shortStickyIsi not exsits")
      }
      if (res.fields.hasOwnProperty("prescribingInformationLink")) {
        this.prescribePdf = res.fields.prescribingInformationLink;
      } else {
        console.log("prescribingInformationLink not exsits")
      }
    })
  }
  public prescribe: any
  getPrescribe() {
    if (this.resourceres.fields.hasOwnProperty("isi")) {
      this.prescribe_id = this.resourceres.fields.isi.sys.id;
    } else {
      console.log("isi not exsits")
    }
    this.contentfulservice.getdata(this.prescribe_id).subscribe(res => {
      if (res.fields.hasOwnProperty("safetyInformation")) {
        this.prescribe = res.fields.safetyInformation;
      } else {
        console.log("safetyInformation not exsits")
      }
    })
  }
  getprovideemailbody() {
    if (this.resourceres.fields.hasOwnProperty("emailContentForSendingPersonalOgeqsa")) {
      let body = this.resourceres.fields.emailContentForSendingPersonalOgeqsa;
      this.provideemailbody = this._returnHtmlFromRichText(body)
      let url = this.ogeUrl
      const newUrls = url.replace('http://localhost:4200/', 'https://ogesupport.com/')
      this.emailcustomLink = newUrls; // Your custom link here
      const updatedHtmlContent = this.provideemailbody.replace(/\[URL Link\]/g, `<a href="${this.emailcustomLink}" target="_blank">${this.emailcustomLink}</a>`);
      this.provideemailbody = updatedHtmlContent;
    } else {
      console.log("emailContentForSendingPersonalOgeqsa not exsits")
    }
    if (this.resourceres.fields.hasOwnProperty("emailSubjectForSendingPersonalOgeqsa")) {
      let body = this.resourceres.fields.emailSubjectForSendingPersonalOgeqsa;
      this.provideemailsubject = body
      // console.log(this.provideemailsubject,"KSJDBCVKAS")
    }else{
      console.log("emailContentForSendingPersonalOgeqsa not exsits")
    }
  }
  get f() {
    return this.provideForm.controls;
  }
  provideEmail() {
    let url = this.ogeUrl
    const newUrls = url.replace('http://localhost:4200/', 'https://ogesupport.com/')
    let resurl = "<a href='" + newUrls + "'>Resource URL</a>"
    this.submitted = true
    if (!this.provideForm.invalid) {
      console.log(this.provideemailbody)
      if (typeof this.provideemailbody === 'undefined') {
        this.provideemailbody = resurl;
      }
      if (typeof this.provideemailsubject === 'undefined') {
        this.provideemailsubject = "A resource has been shared with you";
      }
      this.provideForm.get('body').setValue(this.provideemailbody);
      this.provideForm.get('ogeUrl').setValue(this.ogeUrl);
      this.provideForm.get('drug').setValue(this.drug);
      this.provideForm.get('subject').setValue(this.provideemailsubject);
      this.provideForm.value.body = encodeURI(this.provideemailbody);
      console.log(this.provideForm.value)
      let url = 'https://api.ogesupport.com/v1/resources/request';
      this.http.post(url, this.provideForm.value).subscribe(res => {
        this.toast.success('Mail Submitted')
        console.log(res);
      })
    }
  }
  //brand disease resourses
  async getbrandresource() {
    try {
      if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
        this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
        const res = await this.contentfulservice.getdata(this.branddiseaseResources_id).pipe(this.retryWithDelay()).toPromise();
        await Promise.all([
          this.getPrescribeFile(res),
          this.getCopyright(res),
          this.getContent(res)
        ]);
        this.brandresourceres = res;
        await this.getDynamicmsg(res);
        await this.getprescribingInformation(res);
        await this.getLogoimage(res);
        await this.getColor(res);
        await this.getdrugrelatedbutton(res);
      } else {
        console.log("branddiseaseResources not exists");
      }
    } catch (error) {
      console.error('Error in brand resource fetching:', error);
    }
  }
  async getPrescribeFile(res) {
    try {
      if (res.fields.hasOwnProperty("prescribingInformation")) {
        const prescribingInformationRes = await this.contentfulservice.getdata(res.fields.prescribingInformation.sys.id).toPromise();
        if (prescribingInformationRes.fields.hasOwnProperty("prescribingInformation")) {
          const presinfo2 = prescribingInformationRes.fields.prescribingInformation.sys.id;
          const presinfo2Res = await this.contentfulservice.getdata(presinfo2).toPromise();
          if (presinfo2Res.fields.hasOwnProperty("prescribingInformationPdf")) {
            const assetsid = presinfo2Res.fields.prescribingInformationPdf.sys.id;
            const assetsRes = await this.contentfulservice.getAssets('/' + assetsid + '/').toPromise();
            this.getfile = assetsRes.fields.file.url;
          } else if (presinfo2Res.fields.hasOwnProperty('prescribingInformationLink')) {
            this.getfile = presinfo2Res.fields.prescribingInformationLink;
          } else {
            console.log("prescribingInformationPdf not exists");
          }
        } else {
          console.log("prescribingInformation not exists");
        }
      } else {
        console.log("prescribingInformation not exists");
      }
    } catch (error) {
      console.error('Error in getPrescribeFile:', error);
    }
  }
  async getCopyright(res) {
    try {
      if (res.fields.hasOwnProperty("footer")) {
        this.copyRights_id = res.fields.footer.sys.id;
        const footerRes = await this.contentfulservice.getdata(this.copyRights_id).toPromise();
        this.footerres = footerRes;
        if (footerRes.fields.hasOwnProperty("copyright")) {
          this.copyRights = footerRes.fields.copyright;
        } else {
          console.log("copyrights not exists");
        }
        this.getCslimage();
      } else {
        console.log("footer not exists");
      }
    } catch (error) {
      console.error('Error in getCopyright:', error);
    }
  }
  getCslimage() {
    this.logoimgid = this.footerres.fields.logoimage[0].sys.id;
    this.contentfulservice.getdata(this.logoimgid).subscribe(res => {
      let bid3 = res.fields.imageWrapper.sys.id;
      this.contentfulservice.getdata(bid3).subscribe(res => {
        let bid4 = res.fields.image.sys.id;
        this.contentfulservice.getAssets('/' + bid4 + '/').subscribe(imgData =>
          this.landFootImg = imgData.fields.file.url
        );
      })
    })
  }
  async getContent(res) {
    try {
      if (res.fields.hasOwnProperty("healthToolTrigger")) {
        this.paraContent_id = res.fields.healthToolTrigger.sys.id;
        const paraContentRes = await this.contentfulservice.getdata(this.paraContent_id).toPromise();
        if (paraContentRes.fields.hasOwnProperty("triggerCopy")) {
          this.paraContent = paraContentRes.fields.triggerCopy;
        } else {
          console.log("triggerCopy not exists");
        }
      } else {
        console.log("triggerCopy not exists");
      }
    } catch (error) {
      console.error('Error in getContent:', error);
    }
  }
  async getDynamicmsg(res) {
    try {
      if (res.fields.hasOwnProperty("dynamicSystemMessages")) {
        this.dynamicMsg2_id = res.fields.dynamicSystemMessages.sys.id;
        const dynamicMsg2Res = await this.contentfulservice.getdata(this.dynamicMsg2_id).toPromise();
        const dynamicMsg2 = dynamicMsg2Res.fields.messages[0].sys.id;
        const dynamicMsgRes = await this.contentfulservice.getdata(dynamicMsg2).toPromise();
        this.dynamicMsg = dynamicMsgRes.fields.heading;
      } else {
        console.log("dynamicSystemMessages not exits");
      }
    } catch (error) {
      console.error('Error in getDynamicmsg:', error);
    }
  }
  async getprescribingInformation(res) {
    try {
      if (res.fields.hasOwnProperty('prescribingInformation')) {
        this.presinfo_id = res.fields.prescribingInformation.sys.id;
        const presinfoRes = await this.contentfulservice.getdata(this.presinfo_id).toPromise();
        if (presinfoRes.fields.hasOwnProperty('prescribingInformation')) {
          const p_id = presinfoRes.fields.prescribingInformation.sys.id;
          const pRes = await this.contentfulservice.getdata(p_id).toPromise();
          if (pRes.fields.hasOwnProperty('prescribingInformationPdf')) {
            const ppdf_id = pRes.fields.prescribingInformationPdf.sys.id;
            this.presinternalname = pRes.fields.internalName;
            const assetsRes = await this.contentfulservice.getAssets('/' + ppdf_id + '/').toPromise();
            this.p_file = assetsRes.fields.file.url;
          } else if (pRes.fields.hasOwnProperty('prescribingInformationLink')) {
            this.p_file = pRes.fields.prescribingInformationLink;
          } else {
            console.log("prescribingInformationPdf not exists");
          }
        } else {
          console.log("prescribingInformation not exists");
        }
      } else {
        console.log("prescribingInformation not exists");
      }
    } catch (error) {
      console.error('Error in getprescribingInformation:', error);
    }
  }
  async getLogoimage(res) {
    try {
      if (res.fields.hasOwnProperty("branddiseaseHeader")) {
        this.brandheader_id = res.fields.branddiseaseHeader.sys.id;
        const brandHeaderRes = await this.contentfulservice.getdata(this.brandheader_id).toPromise();
        if (brandHeaderRes.fields.hasOwnProperty("logoimage")) {
          const a = brandHeaderRes.fields.logoimage.sys.id;
          const aRes = await this.contentfulservice.getdata(a).toPromise();
          if (aRes.fields.hasOwnProperty("imageWrapper")) {
            const b = aRes.fields.imageWrapper.sys.id;
            const bRes = await this.contentfulservice.getdata(b).toPromise();
            if (bRes.fields.hasOwnProperty("image")) {
              const c = bRes.fields.image.sys.id;
              const cRes = await this.contentfulservice.getAssets('/' + c + '/').toPromise();
              if (cRes.fields.hasOwnProperty("file")) {
                this.logo = cRes.fields.file.url;
                const a = res.fields.branddiseaseHeader.sys.id;
                const aRes = await this.contentfulservice.getdata(a).toPromise();
                const b = aRes.fields.headerIsi.sys.id;
                const bRes = await this.contentfulservice.getdata(b).toPromise();
                this.indication = bRes.fields.copyBlock;
                this.indicationTitle = bRes.fields.heading;
              } else {
                console.log("logo image not exists");
              }
            } else {
              console.log("image not exists");
            }
          } else {
            console.log("imageWrapper not exists");
          }
        } else {
          console.log("logoimage not exists");
        }
      } else {
        console.log("branddiseaseHeader not exists");
      }
    } catch (error) {
      console.error('Error in getLogoimage:', error);
    }
  }
  async getColor(res) {
    try {
      let branding = res.fields.branding.sys.id;
      this.contentfulservice.getdata(branding).subscribe(res => {
        if (res.fields.hasOwnProperty("primaryColor")) { this.resourceprimarycolor = res.fields.primaryColor.value; } else { console.log("resourceprimarycolor not exists") }
        if (res.fields.hasOwnProperty("secondaryColor")) { this.resourcesecondarycolor = res.fields.secondaryColor.value; } else { console.log("resourcesecondarycolor not exists") }
        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor1")) { this.gradient1 = res.fields.brandHeaderBackgroundGradientColor1.value } else { console.log("brandHeaderBackgroundGradientColor1 not exists") }
        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor2")) { this.gradient2 = res.fields.brandHeaderBackgroundGradientColor2.value } else { console.log("brandHeaderBackgroundGradientColor2 not exists") }
        if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor3")) { this.gradient3 = res.fields.brandHeaderBackgroundGradientColor3.value } else { console.log("brandHeaderBackgroundGradientColor3 not exists") }
        if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor1")) { this.footgradient1 = res.fields.brandFooterBackgroundGradientColor1.value } else { console.log("brandFooterBackgroundGradientColor1 not exists") }
        if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor2")) { this.footgradient2 = res.fields.brandFooterBackgroundGradientColor2.value } else { console.log("brandFooterBackgroundGradientColor2 not exists") }
        if (res.fields.hasOwnProperty("fontColor")) { this.resourcefontColor = res.fields.fontColor.value } else { console.log("resourcefontColor not exists") }
        if (res.fields.hasOwnProperty("fontLinkColor")) { this.resourcefontLinkColor = res.fields.fontLinkColor.value } else { console.log("fontLinkColor not exists") }
        if (res.fields.hasOwnProperty("fontLinkRolloverColor")) { this.resourcefontLinkRolloverColor = res.fields.fontLinkRolloverColor.value } else { console.log("fontLinkRolloverColor not exists") }
        if (res.fields.hasOwnProperty("h1Color")) { this.resourceh1color = res.fields.h1Color.value } else { console.log("h1Color not exists") }
        if (res.fields.hasOwnProperty("horizontalRule")) { this.resourcehorizontalRule = res.fields.horizontalRule.value } else { console.log("horizontalRule not exists") }
        if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
        if (res.fields.hasOwnProperty("buttonBackgroundColor")) { this.resourcebuttonBackgroundColor = res.fields.buttonBackgroundColor.value } else { console.log("buttonBackgroundColor not exists") }
        if (res.fields.hasOwnProperty("buttonFontColor")) { this.resourcebuttonFontColor = res.fields.buttonFontColor.value } else { console.log("buttonFontColor not exists") }
        if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
        if (res.fields.hasOwnProperty("buttonRolloverFontColor")) { this.resourcebuttonRolloverFontColor = res.fields.buttonRolloverFontColor.value } else { console.log("buttonRolloverFontColor not exists") }
        
        if (res.fields.hasOwnProperty("brandFooterBackgroundColor")) { this.resourcebrandFooterBackgroundColor = res.fields.brandFooterBackgroundColor.value } else { console.log("brandFooterBackgroundColor not exists") }
        if (res.fields.hasOwnProperty("brandFooterFontColor")) { this.resourcebrandFooterFontColor = res.fields.brandFooterFontColor.value } else { console.log("brandFooterFontColor not exists") }
        
        if (res.fields.hasOwnProperty("resourceHeaderColor")) { this.resourceHeaderColor = res.fields.resourceHeaderColor.value } else { console.log("resourceHeaderColor not exists") }
        if (res.fields.hasOwnProperty("headerIndicationHeaderFontSize")) { this.headerIndicationHeaderFontSize = res.fields.headerIndicationHeaderFontSize } else { console.log("headerIndicationHeaderFontSize not exists") }
        if (res.fields.hasOwnProperty("headerIndicationCopyFontSize")) { this.headerIndicationCopyFontSize = res.fields.headerIndicationCopyFontSize } else { console.log("headerIndicationCopyFontSize not exists") }
        if (res.fields.hasOwnProperty("headerIndicationFontColor")) { this.headerIndicationFontColor = res.fields.headerIndicationFontColor.value } else { console.log("headerIndicationFontColor not exists") }
        if (res.fields.hasOwnProperty("isiHeadersFontSize")) { this.isiHeadersFontSize = res.fields.isiHeadersFontSize } else { console.log("isiHeadersFontSize not exists") }
        if (res.fields.hasOwnProperty("isiHeadersColors")) { this.isiHeadersColors = res.fields.isiHeadersColors.value } else { console.log("isiHeadersColors not exists") }
        if (res.fields.hasOwnProperty("isiTextFontSize")) { this.isiTextFontSize = res.fields.isiTextFontSize } else { console.log("isiTextFontSize not exists") }
        if (res.fields.hasOwnProperty("bodyTextFontSize")) { this.bodyTextFontSize = res.fields.bodyTextFontSize } else { console.log("bodyTextFontSize not exists") }
        //Font Weigth
        if (res.fields.hasOwnProperty("bodyTextFontWeight")) { this.bodyTextFontWeight = res.fields.bodyTextFontWeight } else { console.log("bodyTextFontWeight not exists") }
        if (res.fields.hasOwnProperty("isiTextFontWeight")) { this.isiTextFontWeight = res.fields.isiTextFontWeight } else { console.log("isiTextFontWeight not exists") }
        //Line Height
        if (res.fields.hasOwnProperty("bodyTextLineHeight")) { this.bodyTextLineHeight = res.fields.bodyTextLineHeight } else { console.log("bodyTextLineHeight not exists") }
        if (res.fields.hasOwnProperty("isiTextLineHeight")) { this.isiTextLineHeight = res.fields.isiTextLineHeight } else { console.log("isiTextLineHeight not exists") }
        document.documentElement.style.setProperty('--resourceprimarycolor', this.resourceprimarycolor ? this.resourceprimarycolor : "#3254a2");
        document.documentElement.style.setProperty('--resourcesecondarycolor', this.resourcesecondarycolor ? this.resourcesecondarycolor : "#691c32");
        document.documentElement.style.setProperty('--gradient1', this.gradient1);
        document.documentElement.style.setProperty('--gradient2', this.gradient2);
        document.documentElement.style.setProperty('--gradient3', this.gradient3);
        document.documentElement.style.setProperty('--footgradient1', this.footgradient1);
        document.documentElement.style.setProperty('--footgradient2', this.footgradient2);
        document.documentElement.style.setProperty('--resourceLinkColor', this.resourcefontLinkColor ? this.resourcefontLinkColor : "#3254a2");
        document.documentElement.style.setProperty('--resourceLinkRolloverColor', this.resourcefontLinkRolloverColor ? this.resourcefontLinkRolloverColor : "#691c32");
        document.documentElement.style.setProperty('--resourceh1Color', this.resourceh1color ? this.resourceh1color : "#3254a2");
        document.documentElement.style.setProperty('--resourcehorizontalRuleColor', this.resourcehorizontalRule ? this.resourcehorizontalRule : "#3254a2");
        document.documentElement.style.setProperty('--resourcebuttonBackgroundColor', this.resourcebuttonBackgroundColor ? this.resourcebuttonBackgroundColor : "#3254a2");
        document.documentElement.style.setProperty('--resourcebuttonBackgroundRollOverColor', this.resourcebuttonBackgroundRollOverColor ? this.resourcebuttonBackgroundRollOverColor : "#691c32");
        document.documentElement.style.setProperty('--resourcebuttonfontcolor', this.resourcebuttonFontColor ? this.resourcebuttonFontColor : "#ffffff");
        document.documentElement.style.setProperty('--resourcebuttonhoverfontcolor', this.resourcebuttonRolloverFontColor ? this.resourcebuttonRolloverFontColor : "#ffffff");
        
        document.documentElement.style.setProperty('--footergradientColor', this.resourcebrandFooterBackgroundColor ? this.resourcebrandFooterBackgroundColor : "#3254a2");
        document.documentElement.style.setProperty('--footerfontgradientColor', this.resourcebrandFooterFontColor ? this.resourcebrandFooterFontColor : "#3254a2");
        
        document.documentElement.style.setProperty('--resourceHeaderColor', this.resourceHeaderColor ? this.resourceHeaderColor : "#3254a2");
        document.documentElement.style.setProperty('--headerIndicationHeaderFontSize', this.headerIndicationHeaderFontSize ? this.headerIndicationHeaderFontSize : "19px");
        document.documentElement.style.setProperty('--headerIndicationCopyFontSize', this.headerIndicationCopyFontSize ? this.headerIndicationCopyFontSize : "18px");
        document.documentElement.style.setProperty('--headerIndicationFontColor', this.headerIndicationFontColor ? this.headerIndicationFontColor : "#ffffff");
        document.documentElement.style.setProperty('--isiHeadersFontSize', this.isiHeadersFontSize ? this.isiHeadersFontSize : "1 REM");
        document.documentElement.style.setProperty('--isiHeadersColors', this.isiHeadersColors ? this.isiHeadersColors : "#0072ce");
        //Font Size
        document.documentElement.style.setProperty('--isiTextFontSize', this.isiTextFontSize ? this.isiTextFontSize : "1 REM");
        document.documentElement.style.setProperty('--bodyTextFontSize', this.bodyTextFontSize ? this.bodyTextFontSize : "1 REM");
        //Font Weight
        document.documentElement.style.setProperty('--bodyTextFontWeight', this.bodyTextFontWeight ? this.bodyTextFontWeight : "400");
        document.documentElement.style.setProperty('--isiTextFontWeight', this.isiTextFontWeight ? this.isiTextFontWeight : "400");
        //Font Height
        document.documentElement.style.setProperty('--bodyTextLineHeight', this.bodyTextLineHeight ? this.bodyTextLineHeight : "1.25 REM");
        document.documentElement.style.setProperty('--isiTextLineHeight', this.isiTextLineHeight ? this.isiTextLineHeight : "1.25 REM");
        const gradientColors = [this.gradient1, this.gradient2, this.gradient3];
        const gradientString = `linear-gradient(to right, ${gradientColors.join(", ")})`;
        document.documentElement.style.setProperty('--headergradientColor', "#b5b3b4");
        document.getElementById("headgradient").style.background = gradientString;
        document.getElementById("footgradient").style.background = this.resourcebrandFooterBackgroundColor;
        document.getElementById("footfontgradient").style.color = this.resourcebrandFooterFontColor;
      })
    } catch (error) {
      console.error('Error in getColor:', error);
    }
  }
  async getdrugrelatedbutton(res) {
    try {
      if (res.fields.hasOwnProperty("branddiseaseHeader")) {
        this.brandheader_id = res.fields.branddiseaseHeader.sys.id;
        const brandHeaderRes = await this.contentfulservice.getdata(this.brandheader_id).toPromise();
        if (brandHeaderRes.fields.hasOwnProperty("multiDiseaseQsaLinkstabsWrapper")) {
          const a = brandHeaderRes.fields.multiDiseaseQsaLinkstabsWrapper.sys.id;
          const aRes = await this.contentfulservice.getdata(a).toPromise();
          this.realtedbuttonids = aRes.fields.linkstabsbuttons;
          const buttonids = this.realtedbuttonids.map(id => id.sys.id);
          const responseDataArray = [];
          const requests$ = [];
          for (let i = 0; i < buttonids.length; i++) {
            const value = buttonids[i];
            const urlWithQuery = `${value}`;
            if (urlWithQuery !== undefined) {
              requests$.push(from(this.contentfulservice.getdata(urlWithQuery)));
            }
          }
          zip(...requests$).pipe(
            concatMap(responses => {
              responseDataArray.push(...responses);
              if (responseDataArray.length === buttonids.length) {
                this.relatedbuttonarray = responseDataArray;
              }
              return [];
            })
          ).subscribe();
        }
      }
    } catch (error) {
      console.error('Error in getdrugrelatedbutton:', error);
    }
  }
  //Dynamic System Messages
  async getAllDynamicMssg() {
    try {
      if (this.resourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
        this.dymssg_id = this.resourceres.fields.dynamicSystemMessages.sys.id;
        this.dynamicmssgres = await this.contentfulservice.getdata(this.dymssg_id).toPromise();
        await Promise.all([
          this.getIcon(),
          this.getIcon1(),
          this.getIcon2(),
          this.getTopHeading(),
          this.getTopDropdown(),
          this.getTopListed(),
          this.getTopListedDemo(),
          this.getTopListed2()
        ]);
      } else {
        console.log("dynamicSystemMessages not exists");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  async getIcon() {
    try {
      this.icon_id = this.dynamicmssgres.fields.messages[2].sys.id;
      const res = await this.contentfulservice.getdata(this.icon_id).toPromise();
      const iconid2 = res.fields.image.sys.id;
      const iconRes = await this.contentfulservice.getAssets('/' + iconid2 + '/').toPromise();
      this.linkIcon = iconRes.fields.file.url;
    } catch (error) {
      console.error('Error fetching icon:', error);
    }
  }
  async getIcon1() {
    try {
      this.icon1_id = this.dynamicmssgres.fields.messages[1].sys.id;
      const res = await this.contentfulservice.getdata(this.icon1_id).toPromise();
      const iconid2 = res.fields.image.sys.id;
      const iconRes = await this.contentfulservice.getAssets('/' + iconid2 + '/').toPromise();
      this.copyIcon = iconRes.fields.file.url;
    } catch (error) {
      console.error('Error fetching icon 1:', error);
    }
  }
  async getIcon2() {
    try {
      this.icon2_id = this.dynamicmssgres.fields.messages[3].sys.id;
      const res = await this.contentfulservice.getdata(this.icon2_id).toPromise();
      const iconid2 = res.fields.image.sys.id;
      const iconRes = await this.contentfulservice.getAssets('/' + iconid2 + '/').toPromise();
      this.searchIcon = iconRes.fields.file.url;
    } catch (error) {
      console.error('Error fetching icon 2:', error);
    }
  }
  async getTopHeading() {
    try {
      this.heading_id = this.dynamicmssgres.fields.messages[0].sys.id;
      const res = await this.contentfulservice.getdata(this.heading_id).toPromise();
      this.heading = res.fields.heading;
    } catch (error) {
      console.error('Error fetching top heading:', error);
    }
  }
  async getTopDropdown() {
    try {
      this.list_id = this.dynamicmssgres.fields.messages[1].sys.id;
      const res = await this.contentfulservice.getdata(this.list_id).toPromise();
      if (res.fields.messagecontent.content[0].content[0].value !== undefined) {
        this.firstList = res.fields.messagecontent.content[0].content[0].value;
      }
      if (res.fields.messagecontent.content[0].content[1].content[0].value !== undefined) {
        this.firstListUrlValue = res.fields.messagecontent.content[0].content[1].content[0].value;
      }
      if (res.fields.messagecontent.content[0].content[1].data.uri !== undefined) {
        this.firstListUrl = res.fields.messagecontent.content[0].content[1].data.uri;
      }
      if (res.fields.messagecontent.content[0].content[2].value !== undefined) {
        this.firstListValue = res.fields.messagecontent.content[0].content[2].value;
      }
    } catch (error) {
      console.error('Error fetching top dropdown:', error);
    }
  }
  async getTopListed() {
    try {
      this.list1_id = this.dynamicmssgres.fields.messages[0].sys.id;
      console.log('this.dynamicmssgres',this.dynamicmssgres);
      const res = await this.contentfulservice.getdata(this.list1_id).toPromise();
      this.getStartedContent = res.fields.copyBlock;
      this.ginacontent = this.getStartedContent.content[0].content[2].content[0].content[1].value;
      console.log('this.ginacontent',this.ginacontent);
    } catch (error) {
      console.error('Error fetching top listed:', error);
    }
  }
  async getTopListedDemo() {
    try {
      this.list2_id = this.dynamicmssgres.fields.messages[2].sys.id;
      this.contentfulservice.getdata(this.list2_id).subscribe(res => {
        if (res.fields.messagecontent.content[0].content[1].content[0].value !== undefined) {
          this.secondList = res.fields.messagecontent.content[0].content[1].content[0].value
        }
        if (res.fields.messagecontent.content[0].content[1].data.uri !== undefined) {
          this.secondListUrl = res.fields.messagecontent.content[0].content[1].data.uri
        }
        if (res.fields.messagecontent.content[0].content[2].value !== undefined) {
          this.secondListValue = res.fields.messagecontent.content[0].content[2].value
        }
        if (res.fields.messagecontent.content[0].content[3].content[0].value !== undefined) {
          this.secondListValue2 = res.fields.messagecontent.content[0].content[3].content[0].value
        }
        if (res.fields.messagecontent.content[0].content[3].data.uri !== undefined) {
          this.secondListValue2Url = res.fields.messagecontent.content[0].content[3].data.uri;
          // alert(this.secondListValue2Url)
        }
        if (res.fields.messagecontent.content[0].content[4].value !== undefined) {
          this.secondListValue2Sl = res.fields.messagecontent.content[0].content[4].value
        }
        if (res.fields.messagecontent.content[0].content[5].content[0].value !== undefined) {
          this.secondListValue2Tl = res.fields.messagecontent.content[0].content[5].content[0].value
          console.log(this.secondListValue2Tl)
        }
        if (res.fields.messagecontent.content[0].content[5].data.uri !== undefined) {
          this.secondListValue2TlUrl = res.fields.messagecontent.content[0].content[5].data.uri
          console.log(this.secondListValue2TlUrl)
        }
        if (res.fields.messagecontent.content[0].content[6].value !== undefined) {
          this.secondListValue2Fl = res.fields.messagecontent.content[0].content[6].value
          console.log(this.secondListValue2Fl)
        }
      })
    } catch (error) {
      console.error('Error fetching top listed demo:', error);
    }
  }

  async getTopListed2() {
    try {
      this.list3_id = this.dynamicmssgres.fields.messages[3].sys.id;
      const res = await this.contentfulservice.getdata(this.list3_id).toPromise();
      this.thirdList = res.fields.messagecontent.content[0].content[0].value;
    } catch (error) {
      console.error('Error fetching top listed 2:', error);
    }
  }
  // This function will retry the API call up to 3 times with a 2 second delay between each retry.
  retryWithDelay(maxRetry: number = 3, delay: number = 2000): (src: Observable<any>) => Observable<any> {
    console.log("retried")
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((errors: Observable<any>) =>
          errors.pipe(
            mergeMap((error, i) =>
              i < maxRetry ? timer(delay) : throwError(error)
            )
          )
        )
      );
  }
  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth"
    });
  }
  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }
  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }
  selectedResources: any = [];
  onResourceSelect(event) {
    if (event.checked) {
      this.selectedResources.push(event);
    } else {
      this.selectedResources.pop(event);
    }
  }
  emailContent: any;
  generateEmailContent() {
    this.emailContent = null;
    this.emailContent = "<b> Here is a resource that might be of interest. Please open the link to view:</b> <br>"
    let resourceContent = '';
    if (this.selectedResources.length > 0) {
      this.selectedResources.forEach(resource => {
        resourceContent = resourceContent + "<br><b>" + resource.fields.healthResourceTitle + "</b>" + this._returnHtmlFromRichText(resource.fields.teaser) + " " + "<a href='" + resource.fields.healthResourceUrl + "'>" + resource.fields.healthResourceTitle + ".Pdf</a><br>"
      });
      this.openModal();
    } else {
      this.toast.error("Please select any resource")
    }
    this.emailContent = this.emailContent + resourceContent;
  }
  copySelectedResourceLinks() {
    this.pdfValues = [];
    localStorage.clear();
    this.pdfValues = [];
    if (this.selectedResources.length > 0) {
      this.selectedResources.forEach(resource => {
        this.pdfValues.push(resource.fields.healthResourceUrl);
        this.clipboardService.copyFromContent(this.pdfValues);
        this.toast.success('Copied to clipboard')
      });
    } else {
      this.toast.error("Please select any resource")
    }
  }
  viewPrintSelectedResourcs() {
    localStorage.clear();
    this.pdfValues = [];
    let prescribeInfo = [this.getfile]
    if (this.selectedResources.length > 0) {
      this.selectedResources.forEach((resource, index) => {
        this.pdfValues.push(resource.fields.healthResourcePrintUrl);
        localStorage.setItem('pdfData', JSON.stringify(this.pdfValues));
        localStorage.setItem('prescribe', JSON.stringify(prescribeInfo))
        if (this.selectedResources.length == index + 1) {
          window.open('/pdfViewer')
        }
      });
    } else {
      this.toast.error("Please select any resource")
    }
  }
  mailPdf(event) {
    let index = this.pdfValues.indexOf(event);
    if (index === -1) {
      return this.pdfValues.push(event);
    } else {
      this.pdfValues.splice(index, 1);
    }
  }
  get result() {
    return this.resourceContents.filter(item => item.fields);
  }
  onSubmit() {
    this.myemailForm.value.body = encodeURI(this.emailContent);
    this.contentfulservice.getMailInfo(this.myemailForm.value).subscribe(res => {
      this.toast.success('Mail Sent')
      this.closeModal();
    })
  }
  openModal() {
    document.getElementById("openbutton").click();
  }
  closeModal() {
    this.closebutton.nativeElement.click();
  }
  public options: any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }
  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading</p>';
    } else {
      return documentToHtmlString(richText, this.options);
    }
  }
  getValidPdfUrls(urls: string[]): string[] {
    const validPdfUrls: string[] = [];
    urls.forEach((url) => {
      if (url.toLowerCase().endsWith('.pdf')) {
        validPdfUrls.push(url);
      } else {
        htmlToPdfmake(url, (pdfDoc) => {
          const blob = new Blob([pdfDoc], {
            type: 'application/pdf'
          });
          const convertedUrl = URL.createObjectURL(blob);
          validPdfUrls.push(convertedUrl);
        });
      }
    });
    return validPdfUrls;
  }
  tabURl = ''
  getTabUrl() {
    this.clipboardService.copyFromContent(window.location.href)
    this.tabURl = window.location.href
    if (this.tabURl != '') {
      this.toast.success('URL Copied to ClipBoard')
    } else {
      this.toast.error('something went wrong')
    }
  }
}