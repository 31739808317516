import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intervention-map',
  templateUrl: './intervention-map.component.html',
  styleUrls: ['./intervention-map.component.scss']
})
export class InterventionMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
