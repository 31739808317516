import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

@Component({
  selector: 'app-preview-content',
  templateUrl: './preview-content.component.html',
  styleUrls: ['./preview-content.component.scss']
})
export class PreviewContentComponent implements OnInit {
  pagetitle: any;
  indication: any;
  isi_id: any;

  constructor(private contentfulservice: ContentfulService) { }

  ngOnInit() {

    let url = window.location.href;
    let wordinurl2 = (url.substring(url.lastIndexOf('/') + 1));
    //alert(wordinurl2)
    this.contentfulservice.getdatawithoutId(wordinurl2).subscribe(res => {
      console.log(res.fields.pageTitle)
      this.pagetitle = res.fields.internalName;
      this.isi_id = res.fields.isi.sys.id;
      this.contentfulservice.getdata(this.isi_id).subscribe(res => {
        this.indication = res.fields.indications;
        console.log(this.indication)
      })
    })

  }


  public options:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options);
    }
}


}
