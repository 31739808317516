import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { element, promise } from 'protractor';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful.service';
import { PharmacyService } from './pharmacy.service';

@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit {

  data: any;
  currentIndex = 0;
  myControl = new FormControl('');
  options: string[] = ['Tablet'];
  medList: Array<any> = [];
  medGram: Array<any> = [];
  medQty: Array<any> = [];
  mG: string[] = ['200MG' , '500MG'];
  // optionsss: string[] = ['28', '30', '42', '60' , '180']
  filteredOptions: Observable<string[]>;
  filteredOptionss: Observable<string[]>;
  filteredOptionsss: Observable<string[]>;
  drugname: any;
  zipcode: any;
  passedvalue: any;
  groupArr2: any;
  passObjects: any;

  constructor(private contentfulservice : ContentfulService, private route : Router, private router: ActivatedRoute, private dataService: PharmacyService) { 

    this.medList = [
      {name: "Tablet" },
    ]

    this.medGram = [
      { name: "200 MG" },
      { name: "500 MG" },
    ]

    this.medQty = [
      {  name: "28" },
      {  name: "30" },
      {  name: "42" },
      {  name: "60" },
      {  name: "180" }
    ]
  }

  ngOnInit() {
    //console.log(this.router.snapshot.queryParams);

    this.passedvalue = this.router.snapshot.queryParams;
    this.drugname = this.passedvalue.drugname;
    this.zipcode = this.passedvalue.zipcode;
    //console.log(this.drugname, this.zipcode)
   

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.getData();
  }

  groupArr
  getData(){
    this.contentfulservice.getMedData(this.drugname, this.zipcode).subscribe((res) =>{
      this.data = res.drugs
      this.groupArr = this.data.reduce((r,{chainCode})=>{
        if(!r.some(o=>o.chainCode==chainCode)){
          r.push({chainCode,groupItem:this.data.filter(v=>v.chainCode==chainCode)});
          this.increment()
    }
    return r;
    },[]);
      console.log(this.groupArr)
    })
  }


  increment() {
    this.currentIndex++;
  }

  discount(id){

    //console.log(this.groupArr.groupItem)

  

   this.groupArr.forEach((element) => {
    element.groupItem.forEach((shopname) => {
      if(shopname.pharmacyName === id){
        //console.log(shopname);
        this.passObjects = shopname;
       this.dataService.allPassedData.next(this.passObjects);
       this.dataService.drugName.next(this.drugname);
      }

    })
   })
   //console.log(resultObject) 
    

    //this.route.navigateByUrl('discount/'+`${id}`)
    this.route.navigate(['discount/'+`${id}`, { outlets: { 'cookie' : null } }]);
  }
  // getForGroup(id){
  //   let chain = this;
  //   return new Promise(function(resolve,reject){
  //     chain.contentfulservice.getMedData().subscribe(res =>{
  //       resolve(res.drugs.chainCode);
  //       console.log(res.drugs.chainCode)
  //     }, err=>{
  //       reject(console.log(err))
  //     })
  //   })
  // }

  // groupData:any
  // getGroupContent(){
  //   var groups = new Set(this.data.map(item => item.chainCode));
  //   this.groupData = [];
  //   groups.forEach(g =>
  //     this.groupData.push({
  //     name: g,
  //     values: this.data.filter(i => i.chainCode === g)
  //   }
  //   ))
  //   if(groups.size == this.groupData.length){
  //     this.groupData.foreach(res =>{
  //       this.getForGroup(res.name).then((groupName)=>{
  //         res.name = groupName
  //       })
  //     })
  //   }
  // }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  

}

