import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PharmacyService {
 
 
allPassedData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
drugName: BehaviorSubject<any> = new BehaviorSubject<any>([]);
constructor() {}

storePassedObject(passedData, _drugName) {
    this.allPassedData.next(passedData);
    this.drugName.next(_drugName);
}
// here instead of retrieve like this you can directly subscribe the property in your components
retrievePassedObject() {
    return this.allPassedData;
    
}
}
